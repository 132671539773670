import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { container } from "tsyringe";
import AdminFileService from "services/admin/FileService";
import StatusIcon from "components/materials/StatusIcon";
import MenuModal from "components/elements/MenuModal";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import ProjectService from "services/admin/ProjectService";
import ProjectUpdateStatusRequestResource from "common/resources/Project/ProjectUpdateStatusRequestResource";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { EyeIcon } from "@heroicons/react/20/solid";
import { DocumentIcon } from "@heroicons/react/20/solid";
import { ArchiveBoxArrowDownIcon } from "@heroicons/react/20/solid";
import { ArchiveBoxIcon } from "@heroicons/react/20/solid";
import { useCallback, useEffect, useRef, useState } from "react";
import DropDown from "components/elements/DropDown";

const adminFileService = container.resolve(AdminFileService);
const projectService = container.resolve(ProjectService);

type IProps = {
	project: ProjectResponseResource;
	onRowClick: (project: ProjectResponseResource) => void;
	onProjectStatusChange: () => void;
	onProjectArchiveChange: () => void;
};

export default function Options(props: IProps) {
	const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const toggleStatusMenu = useCallback(() => {
		setIsStatusMenuOpen((prev) => !prev);
	}, []);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (!isStatusMenuOpen) return;
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsStatusMenuOpen(false);
			}
		},
		[isStatusMenuOpen],
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	const handleStatusChange = async (index: number) => {
		const status = () => {
			switch (index) {
				case 0:
					return EProjectStatus.STUDYING;
				case 1:
					return EProjectStatus.ACCEPTED;
				case 2:
					return EProjectStatus.REFUSED;
				default:
					return EProjectStatus.STUDYING;
			}
		};

		const request = ProjectUpdateStatusRequestResource.hydrate<ProjectUpdateStatusRequestResource>({
			status: status(),
		});

		await projectService.updateStatus(props.project.id, request);
		props.onProjectStatusChange();
	};

	const downloadScenario = () => {
		const folder = "SCENARIO";
		const fileId = props.project.scenario?.file?.id;
		if (!fileId) {
			console.error("No file id found for scenario");
			return;
		}
		const url = adminFileService.getFileUrl(folder, fileId);
		window.open(url, "_blank");
	};

	const downloadCv = () => {
		const folder = "CV";
		const fileId = props.project.cv?.id;
		if (!fileId) {
			console.error("No file id found for cv");
			return;
		}
		const url = adminFileService.getFileUrl(folder, fileId);
		window.open(url, "_blank");
	};

	const archiveProject = async () => {
		await projectService.archive(props.project.id);
		props.onProjectArchiveChange();
	};

	const restoreProject = async () => {
		await projectService.restore(props.project.id);
		props.onProjectArchiveChange();
	};

	const onClick = () => {
		props.onRowClick(props.project);
	};

	return (
		<MenuModal
			options={[
				<div
					onClick={(e) => {
						e.stopPropagation();
						downloadCv();
					}}
					className={classes["option-item"]}>
					<ArrowDownTrayIcon className={classes["icons"]} />
					<Typography type="span" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.admin_dashboard.table.buttons.cv)}
					</Typography>
				</div>,
				<div
					onClick={(e) => {
						e.stopPropagation();
						downloadScenario();
					}}
					className={classes["option-item"]}>
					<ArrowDownTrayIcon className={classes["icons"]} />
					<Typography type="span" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.admin_dashboard.table.buttons.scenario)}
					</Typography>
				</div>,
				<div onClick={onClick} className={classes["option-item"]}>
					<EyeIcon className={classes["icons"]} />
					<Typography type="span" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.admin_dashboard.table.buttons.data)}
					</Typography>
				</div>,
				<div
					onClick={(e) => {
						e.stopPropagation();
						toggleStatusMenu();
					}}
					className={classes["option-item"]}>
					<DropDown
						fixedMenuOnSmallScreen
						options={[
							<StatusIcon status={EProjectStatus.STUDYING} withDescription />,
							<StatusIcon status={EProjectStatus.ACCEPTED} withDescription />,
							<StatusIcon status={EProjectStatus.REFUSED} withDescription />,
						]}
						onOptionClick={handleStatusChange}
						chevronDirection="right"
						menuClassName={classes["menu"]}>
						<div className={classes["menu-container"]}>
							<div className={classes["button"]}>
								<DocumentIcon className={classes["icons"]} />
								<Typography type="span" size="medium" weight="regular">
									{I18n.trslt(I18n.asset.pages.admin_dashboard.table.buttons.status)}
								</Typography>
							</div>
						</div>
					</DropDown>
				</div>,
				<>
					{props.project.archivedAt ? (
						<div onClick={restoreProject} className={classes["option-item"]}>
							<ArchiveBoxIcon className={classes["icons"]} />
							<Typography type="span" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.table.buttons.restore)}
							</Typography>
						</div>
					) : (
						<div onClick={archiveProject} className={classes["option-item"]}>
							<ArchiveBoxArrowDownIcon className={classes["icons"]} />
							<Typography type="span" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.table.buttons.archive)}
							</Typography>
						</div>
					)}
				</>,
			]}>
			<EllipsisVerticalIcon width={24} height={24} />
		</MenuModal>
	);
}
