import PageTemplate from "components/PageTemplate";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { useCallback, useContext, useRef, useState } from "react";
import { UserContext } from "contexts/User";
import DefaultAvatar from "assets/images/default-avatar.svg";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import { OmitRecursively, ValidationError } from "common/resources/Resource";
import Input from "components/materials/Form/InputElement";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import UserAccountInfoUpdateRequestResource from "common/resources/User/UserAccountInfoUpdateRequestResource";
import { container } from "tsyringe";
import { Link, useNavigate } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import Select from "components/materials/Form/SelectElement";
import { EGender } from "common/enums/Gender/EGenter";
import UserService from "services/UserService";

const userService = container.resolve(UserService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function Profile() {
	const navigate = useNavigate();
	const inputFileRef = useRef<HTMLInputElement>(null);
	const { user, updateUser } = useContext(UserContext);
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [profilePicture, setProfilePicture] = useState<File | undefined>(undefined);
	const [image, setImage] = useState<string | null>(null);

	const onProfilePictureClick = useCallback(() => {
		if (inputFileRef.current) {
			inputFileRef.current.click();
		}
	}, []);

	const onProfilePictureChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setProfilePicture(file);
			const reader = new FileReader();
			reader.onload = (e) => {
				setImage(e.target?.result as string);
			};
			reader.readAsDataURL(file);
		}
	}, []);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);

			const userUpdatedInfo: OmitRecursively<UserAccountInfoUpdateRequestResource, "validateOrReject"> = {
				address: formData["address"] as string,
				city: formData["city"] as string,
				firstName: formData["firstName"] as string,
				lastName: formData["lastName"] as string,
				phoneNumber: formData["phoneNumber"] as string,
				zipCode: formData["zipCode"] as string,
				gender: formData["gender"] as EGender,
				birthDate: new Date(formData["birthDate"] as string)
			};

			if (formData["password"] !== "") {
				userUpdatedInfo.password = formData["password"] as string;
				userUpdatedInfo.confirmPassword = formData["password"] as string;
			}

			const userRequest = UserAccountInfoUpdateRequestResource.hydrate<UserAccountInfoUpdateRequestResource>(userUpdatedInfo);
			userRequest
				.validateOrReject()
				.then(() => {
					userService
						.updateAccount(userRequest, profilePicture)
						.then((updatedUser) => {
							updateUser(updatedUser);
							alert(I18n.trslt(I18n.asset.pages.profile.update_success));
							navigate(modules.pages.Home.props.path);
						})
						.catch((error) => {
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[updateUser, navigate, profilePicture],
	);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.profile.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["profile-container"]}>
				<div className={classes["header"]} onClick={onProfilePictureClick}>
					<div className={classes["avatar"]}>
						<img src={image ?? user?.avatar?.url ?? DefaultAvatar} alt="avatar" />
					</div>
					<input type="file" accept="image/*" ref={inputFileRef} className={classes["input-file"]} onChange={onProfilePictureChange} />
					<Typography type="p" size="small" weight="medium">
						{I18n.trslt(I18n.asset.pages.profile.change_avatar)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<div className={classes["all-inputs"]}>
						<div className={classes["inputs-container"]}>
							<Input
								name="firstName"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.firstname.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.firstname.placeholder)}
								className={classes["input"]}
								defaultValue={user?.firstName}
							/>
							<Input
								name="lastName"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.lastname.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.lastname.placeholder)}
								className={classes["input"]}
								defaultValue={user?.lastName}
							/>
						</div>

						<div className={classes["inputs-container"]}>
							<Select
								name="gender"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.sex.label)}
								options={I18n.asset.pages.profile.inputs.sex.options}
								className={classes["input"]}
								defaultValue={user?.gender}
							/>
							<Input
								name="birthDate"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.birthdate.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.birthdate.placeholder)}
								className={classes["input"]}
								type="date"
								max={new Date().toISOString().split("T")[0]}
								defaultValue={user?.birthDate ? new Date(user?.birthDate).toISOString().split("T")[0] : ""}
							/>
						</div>

						<div className={classes["inputs-container"]}>
							<Input
								name="phoneNumber"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.phone.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.phone.placeholder)}
								className={classes["input"]}
								defaultValue={user?.phoneNumber}
							/>
							<Input
								name="city"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.city.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.city.placeholder)}
								className={classes["input"]}
								defaultValue={user?.city}
							/>
						</div>

						<div className={classes["inputs-container"]}>
							<Input
								name="address"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.address.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.address.placeholder)}
								className={classes["input"]}
								defaultValue={user?.address}
							/>
							<Input
								name="zipCode"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.zipCode.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.zipCode.placeholder)}
								className={classes["input"]}
								defaultValue={user?.zipCode}
							/>
						</div>

						<Input
							name="password"
							label={I18n.trslt(I18n.asset.pages.profile.inputs.password.label)}
							placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.password.placeholder)}
							type="password"
							additionalInfo={I18n.trslt(I18n.asset.pages.profile.inputs.password.additional_info)}
							className={classes["input"]}
						/>
					</div>

					<div className={classes["buttons-container"]}>
						<Link to={modules.pages.Home.props.path}>
							<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE}>
								{I18n.trslt(I18n.asset.pages.profile.cancel_button)}
							</Button>
						</Link>

						<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} type="submit">
							{I18n.trslt(I18n.asset.pages.profile.submit_button)}
						</Button>
					</div>
				</Form>
			</div>
		</PageTemplate>
	);
}
