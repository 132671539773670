import PostResponseResource from "common/resources/Post/PostResponseResource";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PostService from "services/PostService";
import { container } from "tsyringe";
import { ForumHeader } from "./components/ForumHeader";
import { CommentSection } from "./components/CommentSection";
import "./classes.scss";
import PageTemplate from "components/PageTemplate";
import I18n from "components/materials/I18n";

const postService = container.resolve(PostService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function Post() {
	const navigate = useNavigate();
	const location = useLocation();
	const [post, setPost] = useState<PostResponseResource | null>(null);

	const getPostIdInUrl = useCallback(() => {
		const url = location.pathname;
		const urlParts = url.split("/");
		const postId = urlParts[urlParts.length - 1];
		return postId;
	}, [location]);

	const fetchPost = useCallback(async () => {
		const postId = getPostIdInUrl();
		if (!postId) {
			console.error("No project id in url");
			navigate(modules.pages.Home.props.path);
			return;
		}
		const post = await postService.getPostById(postId);
		setPost(post);
	}, [getPostIdInUrl, navigate]);

	useEffect(() => {
		fetchPost();
	}, [fetchPost]);

	if (!post) return null;

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.post.title)}>
			<div className="container">
				<ForumHeader post={post} />
				<CommentSection post={post} />
			</div>
		</PageTemplate>
	);
}
