import PostApi from "api/clapaction/PostApi";
import { container, singleton } from "tsyringe";

@singleton()
export default class PostService {
	public api = container.resolve(PostApi);

	public async getPosts(...args: Parameters<PostApi["getPosts"]>) {
		return this.api.getPosts(...args);
	}

	public async getPostById(...args: Parameters<PostApi["getPostById"]>) {
		return this.api.getPostById(...args);
	}

	public async createPost(...args: Parameters<PostApi["createPost"]>) {
		return this.api.createPost(...args);
	}
}
