import classes from "./classes.module.scss";
import { XMarkIcon } from "@heroicons/react/20/solid";
import IconButton from "components/elements/IconButton";
import LazyImage from "components/elements/LazyImage";

type IProps = {
	isOpen: boolean;
	posterUrl: string;
	onClose: () => void;
};

export default function PosterModal(props: IProps) {
	if (!props.isOpen) return null;
	return (
		<div className={classes["root"]}>
			<div className={classes["backdrop"]} onClick={props.onClose} />
			<div className={classes["content"]}>
				<LazyImage src={props.posterUrl} alt="poster" className={classes["poster"]} />
			</div>
			<IconButton className={classes["close-button"]} icon={<XMarkIcon />} onClick={props.onClose} />
		</div>
	);
}
