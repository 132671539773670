import { EGender } from "common/enums/Gender/EGenter";
import { OmitRecursively, ValidationError } from "common/resources/Resource";

import PageTemplate from "components/PageTemplate";
import { ArrowRightIcon } from "@heroicons/react/20/solid";

import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import UserCompleteProfileRequestResource from "common/resources/User/UserCompleteProfileRequestResource";
import Checkbox from "components/elements/Checkbox";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import Input from "components/materials/Form/InputElement";
import Select from "components/materials/Form/SelectElement";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { UserContext } from "contexts/User";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserService from "services/UserService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";

const userService = container.resolve(UserService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function CompleteProfile() {
	const navigate = useNavigate();
	const { user, updateUser } = useContext(UserContext);
	const [hasAcceptedConditions, setHasAcceptedConditions] = useState<boolean>(false);

	const [errors, setErrors] = useState<ValidationError[]>([]);

	useEffect(() => {
		if (user?.address && user?.city && user?.zipCode && user?.phoneNumber && user?.gender && user?.birthDate) {
			navigate(modules.pages.SubmitProject.props.path);
		}
	}, [navigate, user]);

	const onCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setHasAcceptedConditions(e.currentTarget.checked), []);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);

			const userUpdatedInfo: OmitRecursively<UserCompleteProfileRequestResource, "validateOrReject"> = {
				address: formData["address"] as string,
				city: formData["city"] as string,
				firstName: user?.firstName as string,
				lastName: user?.lastName as string,
				phoneNumber: formData["phoneNumber"] as string,
				zipCode: formData["zipCode"] as string,
				gender: formData["gender"] as EGender,
				birthDate: new Date(formData["birthDate"] as string),
			};

			const userRequest = UserCompleteProfileRequestResource.hydrate<UserCompleteProfileRequestResource>(userUpdatedInfo);

			userRequest
				.validateOrReject()
				.then(() => {
					userService
						.updateAccount(userRequest)
						.then((updatedUser) => {
							updateUser(updatedUser);
							navigate(modules.pages.SubmitProject.props.path);
						})
						.catch((error) => {
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
				})
				.catch((error) => {
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[user?.firstName, user?.lastName, updateUser, navigate],
	);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.complete_profile.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["profile-container"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.complete_profile.title)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<div className={classes["all-inputs"]}>
						<div className={classes["inputs-container"]}>
							<Select
								name="gender"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.sex.label)}
								options={I18n.asset.pages.profile.inputs.sex.options}
								className={classes["input"]}
								defaultValue={user?.gender}
							/>
							<Input
								name="birthDate"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.birthdate.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.birthdate.placeholder)}
								className={classes["input"]}
								type="date"
								max={new Date().toISOString().split("T")[0]}
								defaultValue={user?.birthDate ? new Date(user?.birthDate).toISOString().split("T")[0] : ""}
							/>
						</div>
						<div className={classes["inputs-container"]}>
							<Input
								name="address"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.address.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.address.placeholder)}
								className={classes["input"]}
								defaultValue={user?.address}
							/>
						</div>

						<div className={classes["inputs-container"]}>
							<Input
								name="zipCode"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.zipCode.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.zipCode.placeholder)}
								className={classes["input"]}
								defaultValue={user?.zipCode}
							/>
							<Input
								name="city"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.city.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.city.placeholder)}
								className={classes["input"]}
								defaultValue={user?.city}
							/>
						</div>

						<div className={classes["inputs-container"]}>
							<Input
								name="phoneNumber"
								label={I18n.trslt(I18n.asset.pages.profile.inputs.phone.label)}
								placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.phone.placeholder)}
								className={classes["input"]}
								defaultValue={user?.phoneNumber}
							/>
						</div>
					</div>

					<div className={classes["submit-container"]}>
						<div className={classes["buttons-container"]}>
							<Checkbox
								checked={hasAcceptedConditions}
								onChange={onCheckboxChange}
								uid="conditions"
								label={I18n.trslt(I18n.asset.pages.complete_profile.labels.checkbox)}
							/>
							<Button
								variant={EButtonVariant.CONTAINED}
								color={EButtonColor.PRIMARY}
								size={EButtonSize.LARGE}
								fullwidth
								disabled={!hasAcceptedConditions}
								endIcon={<ArrowRightIcon />}
								type="submit">
								{I18n.trslt(I18n.asset.pages.complete_profile.next_button)}
							</Button>
						</div>
					</div>
				</Form>
			</div>
		</PageTemplate>
	);
}
