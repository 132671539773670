import Button, { EButtonVariant, EButtonColor, EButtonSize } from "components/elements/Button";
import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { container } from "tsyringe";
import StatusIcon from "components/materials/StatusIcon";
import Chip from "components/elements/Chip";
import { EyeIcon, ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { formatDate } from "utils/StringUtils";
import I18n from "components/materials/I18n";
import { useState } from "react";
import Modal from "components/elements/Modal";
import ProjectService from "services/ProjectService";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import Options from "./Options";
import AnimatedLoader from "components/elements/AnimatedLoader";

type IProps = {
	project: ProjectResponseResource;
	onViewImageClick: () => void;
	onRowClick: () => void;
	downloadProof: (id: string, title: string) => void;
	onProjectChange: () => void;
};

const projectService = container.resolve(ProjectService);

export default function Row(props: IProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const toogleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const anchorProject = async () => {
		if (props.project.id === null) return;
		await projectService.createAnchor(props.project.id);
		props.onProjectChange();
		toogleModal();
	};

	const date = () => {
		return (
			<Typography type="span" size="medium" weight="regular">
				{formatDate(I18n.getLocale(), new Date(props.project.createdAt ?? ""))}
			</Typography>
		);
	};

	return (
		<>
			<tr className={classes["root"]}>
				<td className={classNames(classes["td-name"])} onClick={props.onRowClick}>
					<div className={classes["link"]}>
						<Typography className={classes["ellipsis"]}>{props.project.title}</Typography>
					</div>
					<div className={classes["responsive"]}>
						<StatusIcon status={props.project.status} />
						<Chip>{date()}</Chip>
					</div>
				</td>

				<td className={classes["td-ai-image"]}>
					{props.project.poster?.file?.url ? (
						<Button
							onClick={props.onViewImageClick}
							variant={EButtonVariant.SELECTOR}
							color={EButtonColor.PRIMARY}
							size={EButtonSize.MEDIUM}
							className={classes["button"]}>
							<EyeIcon className={classes["icons"]} />
						</Button>
					) : (
						<AnimatedLoader />
					)}
				</td>

				<td className={classes["td-proof"]}>
					{props.project.scenario?.anchor?.txLink ? (
						<Button
							onClick={() => props.downloadProof(props.project.id, props.project.title)}
							variant={EButtonVariant.SELECTOR}
							color={EButtonColor.PRIMARY}
							size={EButtonSize.MEDIUM}
							className={classes["button"]}>
							<ArrowDownTrayIcon className={classes["icons"]} />
						</Button>
					) : props.project.scenario?.anchor?.id ? (
						<AnimatedLoader />
					) : (
						<Button onClick={toogleModal} variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.XSMALL}>
							{I18n.trslt(I18n.asset.pages.my_projects.table.anchor)}
						</Button>
					)}
				</td>

				<td className={classes["td-status"]}>
					<StatusIcon status={props.project.status} />
				</td>

				<td className={classes["td-date"]}>{date()}</td>

				<td className={classes["options"]}>
					<Options
						project={props.project}
						onRowClick={props.onRowClick}
						onProjectChange={props.onProjectChange}
						downloadProof={props.downloadProof}
						openAnchorModal={toogleModal}
					/>
				</td>
			</tr>
			<Modal isOpen={isModalOpen} onClose={toogleModal}>
				<div className={classes["anchor-modal"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.my_projects.drawer.modal.title)}
					</Typography>
					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.my_projects.drawer.modal.content1)}
					</Typography>
					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.pages.my_projects.drawer.modal.content2)}
					</Typography>
					<div className={classes["button-container"]}>
						<Button color={EButtonColor.PRIMARY} variant={EButtonVariant.CONTAINED} onClick={anchorProject} fullwidth>
							<Typography type="span" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.my_projects.drawer.button.anchor)}
							</Typography>
						</Button>
						<Button color={EButtonColor.PRIMARY} variant={EButtonVariant.OUTLINED} onClick={toogleModal} fullwidth>
							<Typography type="span" size="medium" weight="regular">
								{I18n.trslt(I18n.asset.pages.my_projects.drawer.modal.button.cancel)}
							</Typography>
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
}
