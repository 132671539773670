import LazyImage from "components/elements/LazyImage";
import classes from "./classes.module.scss";
import ClapActionWhiteLogo from "assets/images/logo-clapaction-white.svg";
import Facebook36x36 from "assets/images/socials/Facebook36x36.svg";
import Instagram36x36 from "assets/images/socials/Instagram36x36.svg";
import Linkedin36x36 from "assets/images/socials/Linkedin36x36.svg";
import { Link } from "react-router-dom";
import Typography from "components/elements/Typography";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";

const modules = container.resolve(ModuleConfig).get().modules;

export default function Footer() {
	return (
		<footer className={classes["root"]}>
			<FooterDesign className={classes["design"]} />
			<div className={classes["content"]}>
				<LazyImage src={ClapActionWhiteLogo} alt="clapaction logo" className={classes["logo"]} />

				<div className={classes["social-links-container"]}>
					<Link to={"https://www.linkedin.com/company/clapaction/"} target="_blank" className={classes["social-link"]}>
						<LazyImage src={Linkedin36x36} alt="linkedin icon" className={classes["social-icon"]} />
					</Link>
					<Link to={"https://www.facebook.com/clapaction13"} target="_blank" className={classes["social-link"]}>
						<LazyImage src={Facebook36x36} alt="facebook icon" className={classes["social-icon"]} />
					</Link>
					<Link
						to={"https://www.instagram.com/clapaction_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="}
						target="_blank"
						className={classes["social-link"]}>
						<LazyImage src={Instagram36x36} alt="instagram icon" className={classes["social-icon"]} />
					</Link>
				</div>

				<div className={classes["links-container"]}>
					<Link to={modules.pages.Contact.props.path} className={classes["link"]}>
						<Typography type="p" size="small" weight="regular" className={classes["text"]}>
							Contact
						</Typography>
					</Link>
					<Link to={modules.pages.LegalNotice.props.path} className={classes["link"]}>
						<Typography type="p" size="small" weight="regular" className={classes["text"]}>
							Mentions légales
						</Typography>
					</Link>
					<Link to={modules.pages.PrivacyPolicy.props.path} className={classes["link"]}>
						<Typography type="p" size="small" weight="regular" className={classes["text"]}>
							Politique de confidentialité
						</Typography>
					</Link>
					<Link to={modules.pages.TermsOfUse.props.path} className={classes["link"]}>
						<Typography type="p" size="small" weight="regular" className={classes["text"]}>
							Conditions d'utilisation
						</Typography>
					</Link>
				</div>

				<Typography type="p" size="small" weight="regular" className={classes["text"]}>
					Copyright © 2024 clapAction
				</Typography>
			</div>
		</footer>
	);
}

function FooterDesign(props: { className?: string }) {
	return (
		<svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="1440" height="80" viewBox="0 0 1440 80" fill="none">
			<path d="M0 37.7154C0 37.7154 437.973 0 720 0C1002.03 0 1440 37.7154 1440 37.7154V80H0V37.7154Z" fill="#0A0A0A" />
		</svg>
	);
}
