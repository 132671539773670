import { UserProvider } from "contexts/User";
import "./di/register";
import Routing from "./Routing";
import { AuthProvider } from "contexts/Auth";
import I18n from "components/materials/I18n";
import { HeaderProvider } from "contexts/Header";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
	return (
		<GoogleOAuthProvider clientId="370726047308-2vsrc9tv2doilfrnhqh6atcm98l6ansc.apps.googleusercontent.com">
			<AuthProvider>
				<UserProvider>
					<HeaderProvider>
						<I18n>
							<Routing />
						</I18n>
					</HeaderProvider>
				</UserProvider>
			</AuthProvider>
		</GoogleOAuthProvider>
	);
}

export default App;
