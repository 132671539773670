import CommentCreateRequestResource from "common/resources/Comment/CommentCreateRequestResource";
import CommentResponseResource from "common/resources/Comment/CommentResponseResource";
import PostResponseResource from "common/resources/Post/PostResponseResource";
import I18n from "components/materials/I18n";
import { dateFnsLocal } from "components/materials/I18n/I18nStore";
import { UserContext } from "contexts/User";
import { format } from "date-fns";
import { ChevronDown, ChevronUp, Heart, MessageCircle, Trash2 } from "lucide-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CommentService from "services/CommentService";
import { container } from "tsyringe";

interface CommentCardProps {
	post: PostResponseResource;
	comment: CommentResponseResource;
	depth?: number;
	onDelete: (commentId: string) => Promise<void>;
}

const commentService = container.resolve(CommentService);

export function CommentCard(props: CommentCardProps) {
	const { user } = useContext(UserContext);
	const [comment, setComment] = useState<CommentResponseResource>(props.comment);
	const [isReplying, setIsReplying] = useState(false);
	const [replyText, setReplyText] = useState("");
	const [isCollapsed, setIsCollapsed] = useState(true);

	const handleLike = useCallback(async () => {
		await commentService.likeComment(comment.id);
		const commentResponse = await commentService.getComment(comment.id);
		setComment(commentResponse);
	}, [comment]);

	const handleSubmitReply = async (e: React.FormEvent) => {
		e.preventDefault();
		if (!replyText.trim()) return;

		const newCommentRequest = CommentCreateRequestResource.hydrate<CommentCreateRequestResource>({
			text: replyText,
			parentId: comment.id,
		});

		await commentService.createComment(props.post.id, newCommentRequest);
		const commentResponse = await commentService.getComment(comment.id);
		setComment(commentResponse);
		setReplyText("");
		setIsReplying(false);
		setIsCollapsed(false);
	};

	useEffect(() => {
		setComment(props.comment);
	}, [props.comment]);

	const toggleCollapse = useCallback(() => {
		setIsCollapsed(!isCollapsed);
	}, [isCollapsed]);

	const maxDepth = 1;
	const depth = props.depth ?? 0;
	const shouldAllowMoreReplies = depth < maxDepth;
	const hasReplies = comment.replies.length > 0;
	const isAdmin = user?.id === props.post.userId;
	const displayName = comment.authorId === props.post.userId ? "L'Équipe du film" : `${comment.author.firstName} ${comment.author.lastName}`;

	return (
		<div className={depth > 0 ? "comment-card__container" : ""}>
			<div className="comment-card__content">
				<div className="comment-card__header">
					<div>
						<p className="comment-card__user-name">{displayName}</p>
						<p className="comment-card__user-timestamp">{format(new Date(props.comment.createdAt ?? ""), "PPPPpp", { locale: dateFnsLocal[I18n.getLocale()] })}</p>
					</div>
					<div className="comment-card__actions">
						<button
							onClick={handleLike}
							className={`comment-card__like-button ${comment.likes.map((like) => like.userId).includes(user!.id) ? "comment-card__like-button--active" : ""}`}>
							<Heart size={14} className={comment.likes.map((like) => like.userId).includes(user!.id) ? "fill-current" : ""} />
							<span>{comment.likes.length}</span>
						</button>
						{isAdmin && (
							<button
								onClick={() => {
									props.onDelete(comment.id);
								}}
								className="comment-card__delete-button"
								title="Supprimer">
								<Trash2 size={16} />
							</button>
						)}
					</div>
				</div>

				<p className="comment-card__text">{comment.text}</p>

				{comment.imageUrl && <img src={comment.imageUrl} alt="Uploaded content" className="comment-card__image" />}

				<div className="comment-card__footer">
					{shouldAllowMoreReplies && (
						<button onClick={() => setIsReplying(!isReplying)} className="comment-card__reply-button">
							<MessageCircle size={16} />
							Répondre
						</button>
					)}
					{hasReplies && (
						<button onClick={toggleCollapse} className="comment-card__collapse-button">
							{isCollapsed ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
							{comment.replies.length} réponse{comment.replies.length > 1 ? "s" : ""}
						</button>
					)}
				</div>

				{isReplying && (
					<form onSubmit={handleSubmitReply} className="comment-card__reply-form">
						<textarea value={replyText} onChange={(e) => setReplyText(e.target.value)} placeholder="Votre réponse..." rows={3} maxLength={1000} />
						<div className="comment-card__reply-form-actions">
							<button type="button" onClick={() => setIsReplying(false)} className="comment-card__reply-form-cancel">
								Annuler
							</button>
							<button type="submit" disabled={!replyText.trim()} className="comment-card__reply-form-submit">
								Répondre
							</button>
						</div>
					</form>
				)}
			</div>

			{!isCollapsed && comment.replies.length > 0 && (
				<div className="comment-card__replies">
					{comment.replies.map((reply) => (
						<CommentCard key={reply.id} comment={reply} depth={depth + 1} post={props.post} onDelete={props.onDelete} />
					))}
				</div>
			)}
		</div>
	);
}
