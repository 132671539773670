import { useCallback } from "react";
import { container } from "tsyringe";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import Modal from "components/elements/Modal";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import ProjectService from "services/admin/ProjectService";
import classes from "./classes.module.scss";

type IProps = {
	project: ProjectResponseResource;
	isOpen: boolean;
	onClose: () => void;
	onAnchorProject: () => Promise<void> | void;
};

const projectService = container.resolve(ProjectService);

export default function AnchorProjectModal(props: IProps) {
	const anchorProject = useCallback(async () => {
		await projectService.createAnchor(props.project.id);
		await props.onAnchorProject();
		props.onClose();
	}, [props]);

	return (
		<Modal className={classes["root"]} isOpen={props.isOpen} onClose={props.onClose}>
			<div className={classes["anchor-modal"]}>
				<Typography type="h1" weight="bold">
					{I18n.trslt(I18n.asset.pages.my_projects.drawer.modal.title)}
				</Typography>
				<Typography type="p" size="medium" weight="regular">
					{I18n.trslt(I18n.asset.pages.my_projects.drawer.modal.content1)}
				</Typography>
				<Typography type="p" size="medium" weight="regular">
					{I18n.trslt(I18n.asset.pages.my_projects.drawer.modal.content2)}
				</Typography>
				<div className={classes["button-container"]}>
					<Button color={EButtonColor.PRIMARY} variant={EButtonVariant.CONTAINED} onClick={anchorProject} fullwidth>
						<Typography type="span" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.my_projects.drawer.button.anchor)}
						</Typography>
					</Button>
					<Button color={EButtonColor.PRIMARY} variant={EButtonVariant.OUTLINED} onClick={props.onClose} fullwidth>
						<Typography type="span" size="medium" weight="regular">
							{I18n.trslt(I18n.asset.pages.my_projects.drawer.modal.button.cancel)}
						</Typography>
					</Button>
				</div>
			</div>
		</Modal>
	);
}
