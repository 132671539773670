import classes from "./classes.module.scss";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";

type IProps = {
	options: React.ReactNode[];
	className?: string;
	children: React.ReactNode;
};

export default function MenuModal(props: IProps) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const toggleMenu = useCallback(() => {
		setIsMenuOpen((prev) => !prev);
	}, []);

	const listOptions = props.options.map((option, index) => (
		<div key={index} className={classes["row"]}>
			{option}
		</div>
	));

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			if (!isMenuOpen) return;
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsMenuOpen(false);
			}
		},
		[isMenuOpen],
	);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [handleClickOutside]);

	return (
		<div className={classNames(classes["root"], props.className)}>
			<div className={classes["menu-container"]} ref={menuRef} onClick={toggleMenu}>
				{props.children}
				{isMenuOpen && (
					<div className={classes["menu"]}>
						{listOptions}
					</div>
				)}
				<div
					className={classNames(classes["background"], {
						[classes["is-open"]!]: isMenuOpen,
					})}
				/>
			</div>
		</div>
	);
}
