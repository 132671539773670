import classes from "./classes.module.scss";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import DefaultAvatar from "assets/images/default-avatar.svg";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import LazyImage from "components/elements/LazyImage";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { formatDate } from "utils/StringUtils";
import { match } from "ts-pattern";

const modules = container.resolve(ModuleConfig).get().modules;

type IProps = {
	project: ProjectResponseResource;
};

function getDescription(description: string) {
	const maxLength = 222;
	return description.length > maxLength ? description.slice(0, maxLength) + "..." : description;
}

export default function ProjectCard(props: IProps) {
	const pitch =
		match(I18n.getLang())
			.with("fr", () => props.project.pitch)
			.with("en", () => props.project.pitch_en)
			.exhaustive() ?? "";
	const projectId = props.project.id;
	const poster = props.project.poster?.file?.url;
	const avatar = props.project.author.avatar?.url ?? "";
	const author = `${props.project.author.firstName} ${props.project.author.lastName}`;
	const publishedAt = formatDate(I18n.getLocale(), new Date(props.project.createdAt ?? ""));
	const theme = I18n.trslt(I18n.asset.enums.EProjectThemes[props.project.theme]);
	const format = I18n.trslt(I18n.asset.enums.EProjectFormats[props.project.format]);
	const title = match(I18n.getLang())
		.with("fr", () => props.project.title)
		.otherwise(() => props.project.title_en);

	return (
		<div className={classes["root"]}>
			<div className={classes["poster-container"]}>
				{poster ? <LazyImage src={poster} alt="Project poster" className={classes["poster-image"]} /> : <Typography>L’image n’a pas pu être générée par IA</Typography>}
			</div>

			<div className={classes["body-container"]}>
				<div className={classes["body"]}>
					<div className={classes["header"]}>
						<div className={classes["author"]}>
							<div className={classes["avatar"]}>
								<img src={avatar ?? DefaultAvatar} alt="Author avatar" className={classes["avatar-image"]} />
							</div>

							<Typography type="p" size="small" weight="bold" fontFamily="secondary">
								{author}
							</Typography>
						</div>

						<div className={classes["infos"]}>
							<div className={classes["info"]}>
								<Typography type="p" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.featured_project.published_at, { var1: publishedAt })}
								</Typography>
							</div>

							<div className={classes["info"]}>
								<Typography type="p" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.featured_project.category)}
									<Typography type="span" weight="medium" className={classes["category"]} color="white">
										{theme}
									</Typography>
								</Typography>
							</div>

							<div className={classes["info"]}>
								<Typography type="p" size="medium" weight="medium">
									{I18n.trslt(I18n.asset.component.featured_project.length)} {format}
								</Typography>
							</div>
						</div>
					</div>

					<div className={classes["content"]}>
						<Typography type="h2" weight="bold">
							{title}
						</Typography>

						<Typography type="p" weight="regular" size="medium">
							{getDescription(pitch)}
						</Typography>
					</div>
				</div>

				<Link to={modules.pages.Project.props.path.replace(":id", projectId)}>
					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} endIcon={<ArrowRightIcon />}>
						{I18n.trslt(I18n.asset.component.featured_project.button)}
					</Button>
				</Link>
			</div>
		</div>
	);
}
