import CommentCreateRequestResource from "common/resources/Comment/CommentCreateRequestResource";
import CommentResponseResource from "common/resources/Comment/CommentResponseResource";
import PostResponseResource from "common/resources/Post/PostResponseResource";
import React, { useCallback, useEffect } from "react";
import CommentService from "services/CommentService";
import { container } from "tsyringe";
import { CommentForm } from "./CommentForm";
import { CommentList } from "./CommentList";

type Props = {
	post: PostResponseResource;
};

const commentService = container.resolve(CommentService);

export function CommentSection(props: Props) {
	const [comments, setComments] = React.useState<CommentResponseResource[]>([]);
	const handleAddComment = async (text: string) => {
		const newCommentRequest = CommentCreateRequestResource.hydrate<CommentCreateRequestResource>({
			text,
		});

		const newComment = await commentService.createComment(props.post.id, newCommentRequest);

		setComments([newComment, ...comments]);
	};

	const fetchComments = useCallback(async () => {
		const comments = await commentService.getComments(props.post.id);
		setComments(comments);
	}, [props.post.id]);

	useEffect(() => {
		fetchComments();
		const removeOnChange = setInterval(() => {
			fetchComments();
		}, 5000);

		return () => {
			clearInterval(removeOnChange);
		};
	}, [fetchComments, props.post.id]);

	const onDelete = useCallback(async (commentId: string) => {
		await commentService.deleteComment(commentId);
		const newComments = filterComments(comments, commentId);
		setComments(newComments);
	}, [comments]);

	return (
		<div className="max-w-3xl mx-auto">
			<div className="space-y-8">
				<CommentForm onSubmit={handleAddComment} post={props.post} />
				<CommentList post={props.post} comments={comments} onDelete={onDelete} />
			</div>
		</div>
	);
}

function filterComments(comments: CommentResponseResource[], commentId: string): CommentResponseResource[] {
	return CommentResponseResource.hydrateArray<CommentResponseResource>(
		comments
			.filter((comment) => comment.id !== commentId)
			.map((comment) => {
				// Filtrer récursivement les réponses
				if (comment.replies && comment.replies.length > 0) {
					return {
						...comment,
						replies: filterComments(comment.replies, commentId),
					};
				}
				return comment;
			}),
	);
}
