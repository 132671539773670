import { ReactNode, useState } from "react";
import classes from "./classes.module.scss";
import Typography from "../Typography";

type IProps = {
	children?: ReactNode;
	title: string;
	placement?: "top";
	arrow?: boolean;
};

export default function Tooltip(props: IProps) {
	const [isHovered, setIsHovered] = useState(false);

	const showArrow = props.arrow ?? true;

	return (
		<div className={classes["root"]}>
			<div className={`${classes["title-container"]} ${!showArrow ? classes["no-arrow"] : ""} ${!isHovered ? classes["hidden"] : ""}`}>
				<div className={classes["title"]}>
					<Typography color="inherit">{props.title}</Typography>
				</div>
			</div>
			<div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				{props.children}
			</div>
		</div>
	);
}
