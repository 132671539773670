import { EProjectFormat } from "common/enums/ProjectFormat/ProjectFormat";
import { EProjectReason } from "common/enums/ProjectReason";
import { EProjectTheme } from "common/enums/ProjectTheme/ProjectTheme";
import { EValidationError } from "common/enums/Validation/ValidationErrors";

const EProjectReasonImplementation: Record<EProjectReason, string> = {
	PROTECT: "I am submitting my project simply to protect it",
	PROTECT_AND_DEVELOPMENT_AID: "I am submitting my project to protect it and submit it for development assistance",
};

const EProjectFormatImplementation: Record<EProjectFormat, string> = {
	court_metrage: "Short Screen",
	documentaire: "Documentary",
	long_metrage: "Feature Film",
	serie: "Series",
};

const EProjectThemeImplementation: Record<EProjectTheme, string> = {
	comedie: "Comedy",
	comedie_romantique: "Romantic Comedy",
	comedie_dramatique: "Dramatic Comedy",
	biopic: "Biopic",
	thriller: "Thriller",
	film_daction: "Action Film",
	film_policier: "Crime Film",
	film_dhorreur: "Horror Film",
	film_historique: "Historical Film",
	science_fiction: "Science Fiction",
	film_danimation: "Animated Film",
	comedie_musicale: "Musical Comedy",
	dramedie: "Dramedy",
	comedie_absurde: "Absurd Comedy",
	thriller_psychologique: "Psychological Thriller",
};

const EValidationErrorImplementation: Record<EValidationError, string> = {
	isArray: "You must select at least one value.",
	isBoolean: "This field is required.",
	isEnum: "This field is required.",
	isInt: "Make sure to enter a whole number, without decimals.",
	isNotEmpty: "This field is required.",
	isNumber: "This field is required. The value must be a number.",
	isObject: "This field is required.",
	isString: "This field is required.",
	isUrl: "Please enter a valid URL.",
	max: "The number must be less than or equal to {{var1}}.",
	min: "The number must be greater than or equal to {{var1}}.",
	badCredentials: "Incorrect credentials. Please try again.",
	emailNotExists: "The email does not exist.",
	isEmail: "Please enter a valid email address.",
	isNumberString: "This field is required. The value must be numeric.",
	isPositive: "The number must be positive.",
	maxLength: "The text length must not exceed {{var1}} characters.",
	passwordNotMatch: "The passwords do not match.",
	isStrongPassword: "The password must be more complex for security reasons.",
	pinCodeNotValid: "The PIN code is not correct.",
	uniqueEmail: "Please use a different email address, this one is already in use.",
	wrongPassword: "The password you entered is incorrect.",
	arrayNotEmpty: "This field is required.",
	match: "The values do not match.",
	minLength: "The text length must be at least {{var1}} characters.",
	emailNotFound: "The email address you provided is not associated with any account.",
	fileNotExists: "The file does not exist.",
	emailCaseSensitive: "The email address must be in lowercase.",
	anchorNotExists: "The anchor does not exist.",
	choiceNotExists: "The choice does not exist.",
	mustBeInThePast: "The date must be in the past.",
	uniqueSha256: "It is not possible to submit the same file twice.",
	invalidPhoneNumber: "Only french or international phone numbers are accepted.",
	invalidLinkedInUrl: "Please enter a valid LinkedIn URL.",
	isDate: "Please enter a valid date.",
};

const trads = {
	common: {
		cancel: "Cancel",
		logout: "Logout",
		search: "Search",
		yes: "Yes",
		no: "No",
		save: "Save",
		back: "Back",
		delete: "Delete",
		add: "Add",
		next: "Next",
	},
	pages: {
		admin_dashboard: {
			page_title: "Submitted projects",
			title: "Submitted projects",
			filters: {
				all_projects: "All projects",
				accepted: "Retained",
				refused: "Not retained",
				studying: "Studying",
				archived: "Archived",
			},
			table: {
				need_to_be_studied: {
					yes: "Yes",
					no: "No",
				},
				headers: {
					project_name: "Project name",
					author: "Author",
					status: "Status",
					date: "Date",
				},
				buttons: {
					cv: "Download CV",
					scenario: "Download scenario",
					data: "Project data",
					status: "Status",
					archive: "Archive",
					restore: "Restore",
				},
				no_cv: "No CV",
				no_scenario: "No scenario",
			},
			drawer: {
				poster: {
					regenerate: "Regenerate a poster",
					pending: "Image generation in progress",
					failed: "Image generation failed",
				},
				author: "Author",
				infos: {
					title: "Informations",
					publication_date: "Publication:",
					genre: "Genre:",
					format: "Format:",
					reason: "Reason:",
				},
				buttons: {
					cv: "Download CV",
					scenario: "Download scenario",
					archive: "Archive",
					restore: "Restore",
				},
			},
		},
		project: {
			page_title: "Project page",
			publication: "Publication: ",
			genre: "Genre: ",
			format: "Format: ",
			buttons: {
				readMore: "Read more",
				readLess: "Read less",
			},
			follow_us: "Follow us on :",
		},
		featuredProjects: {
			grand_saut: {
				page_title: "Le grand saut",
				header: {
					original_idea: "Based on an original idea by:",
					writer: "Written by:",
					publication: "Publication: May 10, 2024",
					genre: "Genre: Romantic Comedy",
					format: "Format: Feature Film",
					box: "Beginning of writing the first version",
					referent: "Referent Agent: Isabelle Frère - Company: If Only",
				},
				content: {
					title: "Le grand saut",
					subtitle: "Until death do them part",
					description:
						"After a breakup and losing his job, Antoine Lambert, a life insurance broker in his thirties, falls into depression. This reflects a time when mental health is more central to our lives than ever. During a failed suicide attempt, he meets Lucie Saglio, also in her thirties and depressed since childhood, who was about to end her life too. From this meeting, an obsession is born: to achieve the perfect suicide together. With this grim project, they embark on a final journey, a succession of events both dark and comedic. They allow themselves everything without limits, finding a goal, meaning, and a desire to live. Maybe this is the best way to do it: the Big Jump.",
				},
				vote: {
					title: "Vote in progress",
					description: "Have you ever experienced such intense despair that it led you to consider ending your life?",
					options: ["Yes", "No"],
				},
				casting: {
					title: "Become a Voice in the Casting",
					description: "In our forum space, the casting director and the director invite you to contribute by suggesting your actor choices for the roles described in their announcements. It’s your turn!",
					button: "View the Forum"
				},
				action: {
					title: "Upcoming Action",
					description:
						"Soon, we will invite three members of our community to discover the first version of the script. The readers' feedback will be valuable for the authors, directors, and producers in developing a second version.",
				},
			},
			malgre_moi: {
				page_title: "Malgré moi",
				header: {
					author: "Laurent Bouhnik",
					publication: "Publication : May 10, 2024",
					genre: "Genre : Psychological Thriller",
					format: "Format : Feature Film",
					referent: "Referent agent: Ghislaine Gracieux",
					box: "Final version reading - Start of casting",
					adaptation: "Adaptation of the novel 'Despite Myself' by Claire Norton",
				},
				content: {
					title: "Malgré moi",
					subtitle: "The Power of Denial",
					description:
						"Théo wants to die. His sister and his two best friends don't want that. But if Marine hadn't missed the Rio/Paris flight that crashed into the sea, she would have left Théo for another man. That drives him mad. That's what he explains to his psychiatrist. But how to know the truth when Théo keeps arranging the story in his own way? It's hard to know the truth if reality is never what you expect.",
				},
				vote: {
					title: "Vote in progress",
					description: "Do you regularly consult a psychologist/psychiatrist?",
					options: ["Yes", "No"],
				},
				action: {
					title: "Upcoming action",
					description:
						"Soon, two of you will have the privilege of reading the final version of the script. Then, the community will express its preferences for the actor they would like to see play the role of a psychiatrist over 60 years old.",
				},
			},
			kdanse: {
				page_title: "KDanse",
				header: {
					author: "Philippe Gray",
					publication: "Publication : May 10, 2024",
					genre: "Genre: Dramedy",
					format: "Format : Feature Film",
					referent: "Referent Agent:  Fred Malek / Agency Caractère",
					box: "Looking for a co-writer. Ideally, we are also looking for a director.",
				},
				content: {
					title: "En K-Danse",
					subtitle: "The Fields of Resilience",
					description:
						"Taking care of the family farm and his mother who has cancer, days become increasingly difficult for David and his father, who are facing major financial problems. The return of his transgender brother Émile, after 16 years of absence, disrupts their daily lives. As their mother is placed in palliative care, Émile has an idea to make quick money: to form a K-Pop group within the village and win the French championship which takes place in a month.",
				},
				vote: {
					title: "Vote in progress",
					description: "Are you familiar with the world of K-POP?",
					options: ["Yes", "No"],
				},
				action: {
					title: "Upcoming action",
					description: "Soon, you will be able to help us by suggesting ideas and voting to find the ideal director for this film.",
				},
			},
			friends: {
				page_title: "Celle qui n'avait pas vu Friends",
				header: {
					author: "Charlotte Gabris",
					publication: "Publication : May 10, 2024",
					genre: "Genre : Absurd Comedy",
					format: "Format : Feature Film",
					box: "Film in financing",
					referent: "Referent Agent:  Alexandra Schamis / AS. Talents",
				},
				content: {
					title: "Celle qui n'avait pas vu Friends",
					subtitle: "When you get dumped on your wedding day...",
					description:
						"Greg meets his future wife Rachel a few minutes before the ceremony. She is so beautiful... He wants to say 'oh my God!!!!' in reference to the series Friends. Rachel doesn't understand his joke... And for good reason, she has never watched this series. Greg is shocked, he doesn't want to get married anymore.",
				},
				vote: {
					title: "Vote in progress",
					description: "Have you ever watched the series Friends?",
					options: ["Yes", "No"],
				},
				action: {
					title: "Upcoming action",
					description: "Soon, you will have the opportunity to help us by participating in the vote to choose the actor for one of the last key roles in the film.",
				},
				casting: {
					title: "Casting confirmed",
					male: "Played by ",
					female: "Played by ",
				},
			},
		},
		terms_of_use: {
			page_title: "Terms of use",
			title: "Terms of use",
			subtitle: "Privacy Policy",
			date: "Effective date: 06/27/2024",
			watch_us: "WATCH'US ('we', 'us', 'our') operates the website https://www.techcannes.com/ and https://www.clapaction.com/",
			collect:
				"This page explains our policies regarding the collection, use and disclosure of personal data when you use our Site and the choices available to you with respect to that data.",
			use_data: "We use your data to provide and improve the Site. By using the Site, you consent to the collection and use of information in accordance with this policy.",
			definitions: {
				title: "Definitions",
				site: "'Site' refers to the website https://www.techcannes.com/ and https://www.clapaction.com/ operated by the company Watch'Us",
				items: [
					"Personal Data: Personal Data means data relating to a living individual who can be identified from such data (or from such data and other information in our possession or likely to come into our possession).",
					"Usage Data: Usage Data is collected automatically and is generated either by use of the Site, or by the Site infrastructure itself (e.g., page view duration).",
					"Cookies : Cookies are small files stored on your device (computer or mobile device).",
					"Data Controller: Data Controller means the natural or legal person (alone, or jointly or in common with other persons) who determines the purposes and manner in which all personal data is processed or is to be processed. For the purposes of this Privacy Policy, we are a Data Controller of your personal data.",
					"Subcontractors (or Service Providers): The term Sub-Contractor (or Service Provider) refers to any natural or legal person who processes data on behalf of the Data Controller. We may use the services of several Service Providers in order to process your data more efficiently",
					"Data Subject (or User): By Data Subject, we mean any living person who uses our Site and is the subject of Personal Data.",
				],
			},
			data_collection_and_use: {
				title: "Data collection and use",
				subtitle: "We collect various types of data for different purposes in order to provide you with our Site and to improve it.",
				types_of_data_collected: {
					title: "Types of data collected",
					personal_data: {
						title: "Personal Data",
						description:
							"When you use our Site, we may ask you to provide certain personally identifiable information that can be used to contact or identify you ('Personal Data'). Personal Data may include, but is not limited to:",
						items: ["E-mail address", "First and last name", "Cookies and Usage Data"],
						use: "We use your Personal Data to send you an answer to your request made via the contact form on our website. We undertake not to send you any promotions or commercial canvassing if you do not give us your consent.",
					},
					usage_data: {
						title: "Usage data",
						description:
							"We may also collect information about how you access and use the Site ('Usage Data'). This Usage Data may include information such as the Internet Protocol address (i.e., IP address) of your computer, browser type, browser version, the pages of our Site that you visit, the date and time of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.",
					},
					tracking_cookies_data: {
						title: "Tracking and cookie data",
						description: "We use cookies and other similar tracking technologies to track activity on our Site and store certain information.",
						definition:
							"Cookies are low-volume data files that may contain an anonymous unique identifier. Cookies are sent to your browser from a website and are stored on your device.",
						consent: "You can accept or decline cookies directly via the cookie control panel in the bottom right-hand corner of the website.",
					},
				},
				use_of_data: {
					title: "Use of data",
					description: "DCF uses the collected data for various purposes:",
					items: [
						"To provide and service our Site",
						"To provide customer support",
						"To collect valuable data or analysis that will allow us to improve our Site",
						"To monitor the use of our Site",
						"To detect, prevent and resolve technical problems",
						"To respond to any questions or requests from you via the contact form",
					],
				},
			},
			legal_basis: {
				title: "Legal basis for the processing of personal data under the General Data Protection Regulation (GDPR)",
				description:
					"If you reside in the European Economic Area (EEA), La DCF's legal basis for collecting and using the personal data described in this Privacy Policy depends on the Personal Data we collect and the precise context in which we collect it.",
				legal_basis: [
					"Because you have authorized us to do so",
					"Because we have a legitimate interest in carrying out this processing and your rights do not override this legitimate interest",
					"To comply with the law",
				],
			},
			data_retention: {
				title: "Data retention",
				description:
					"Watch'Us will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary for us to perform our legal obligations (e.g. to comply with applicable law), resolve disputes and enforce our agreements and policies. The legal retention period for your Personal Data is a maximum of 3 years",
				usage_data_retention:
					"Watch'Us will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period except where such data is used to enhance the security or improve the functionality of our Site or where we are legally required to retain such data for a longer period. The legal retention period for Usage Data is a maximum of 13 months",
			},
			data_transfer: {
				title: "Data transfer",
				description:
					"Information about you, including your Personal Data, may be transferred from your region, province, country or other territorial division to - and stored on - computers located in a place where data protection legislation differs from that of the territory in which you reside.",
				international_transfer:
					"If you reside outside of France and choose to provide us with information, please be aware that we transfer data, including Personal Data, to France and process it there. By accepting this Privacy Policy and submitting this information, you consent to this transfer",
				security_measures:
					"Watch'Us will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy, and your Personal Data will not be transferred to any organization or country unless adequate controls are in place, including with respect to the security of your data and other personal data.",
			},
			data_disclosure: {
				title: "Data disclosure",
				law_enforcement: {
					title: "Data disclosure to law enforcement agencies",
					description:
						"In certain circumstances, Watch'Us may be required to disclose your Personal Data if required to do so by law or in response to valid requests from public authorities (e.g., a court or government agency).",
				},
				legal_requirements: {
					title: "Legal requirements",
					description: "Watch'Us may disclose your Personal Data if it believes in good faith that this is necessary to:",
					items: [
						"Fulfill a legal obligation",
						"Protect and defend the rights or property of DCF",
						"Prevent or investigate possible wrongdoing in connection with the Site",
						"To ensure the personal safety of users of the Site or the public",
						"Protect against civil liability",
					],
				},
			},
			data_security: {
				title: "Data security",
				description:
					"The security of your data is important to us. However, please remember that no method of data transmission over the Internet or method of electronic storage is 100% secure. Although we strive to use commercially acceptable methods to protect your Personal Data, we cannot guarantee its absolute security.",
			},
			user_rights: {
				title: "Data protection rights granted to you by the General Data Protection Regulation (GDPR)",
				description:
					"If you reside in the European Economic Area (EEA), you have certain data protection rights. DCF intends to take reasonable steps to allow you to correct, amend or delete your Personal Data or limit its use.",
				contact_info: "If you would like to know what Personal Data we hold about you and would like it deleted from our systems, please contact us.",
				rights_list: [
					"The right to access, update or delete the information we hold about you. Where this option is offered, you may consult or update your Personal Data or request its deletion by contacting us.",
					"Right of rectification. You have the right to have your data corrected if it is inaccurate or incomplete",
					"Right to object. You have the right to object to our processing of your Personal Data",
					"Right of limitation. You have the right to ask us to limit the processing of your Personal Data",
					"The right to data portability. You have the right to receive a copy of the information we hold about you in a commonly used, structured and machine-readable format.",
					"Right to withdraw consent. You also have the right to withdraw your consent at any time if La DCF has relied on your consent to process your personal data.",
				],
				proof_of_identity: "Please note that we may ask you to prove your identity before responding to requests of this nature.",
				complaint:
					"You have the right to lodge a complaint with a data protection authority about our collection and use of your Personal Data. For more information, please contact your nearest data protection authority in the European Economic Area (EEA).",
			},
			service_providers: {
				title: "Service providers",
				description:
					"We may use third-party companies and individuals to facilitate the provision of our Site ('Service Providers'), provide the Site on our behalf, provide Site-related services or help us analyze how our Site is used.",
				access_restriction:
					"These third parties have access to your Personal Data only to perform these tasks on our behalf and are prohibited from disclosing or using it for any other purpose.",
			},
			external_links: {
				title: "Links to other sites",
				description:
					"Our Site may contain links to other sites that we do not operate. If you click on a third-party link, you will be redirected to that third party's site. We strongly recommend that you review the privacy policy of each site you visit. We have no control over the content, privacy policies or practices of third-party sites or services and disclaim any responsibility for them.",
			},
			policy_updates: {
				title: "Changes to this Privacy Policy",
				description:
					"We reserve the right to update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
				notification: "Before the change takes effect, we will notify you by updating the 'effective date' at the top of this Privacy Policy.",
				advice: "We advise you to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy take effect when they are posted on this page.",
			},
			contact: {
				title: "Contact us",
				description: "If you have any questions about this Privacy Policy or if you have any requests regarding your Personal Data, please contact us:",
				contact_info: {
					contact_person: "Contact DPO : Sarah Llouch",
					email: "By email: watchus13@gmail.com",
					address: "Par voie postale : 15 avenue Hoche, 75008 Paris",
				},
			},
			legal_notice: {
				title: "Legal notice",
				description:
					"This site is created by the company Watch'Us SARL with a capital of 45 000€ (R.C.S. Paris B 482 201 407) located at 15 AV HOCHE 75008 PARIS and chaired by Mrs Sarah Lelouch. Email address: watchus13@gmail.com",
				publication_director: "Directeur de la publication : Mme Sarah Lelouch.",
				hosting_info: {
					company: "The site is hosted by OVH",
					details:
						"SAS au capital de 10 174 560 €\nRCS Lille Métropole 424 761 419 00045\nCode APE 2620Z\nN° TVA : FR 22 424 761 419\nSiège social : 2 rue Kellermann - 59100 Roubaix - France",
					subsidiary:
						"OVH SAS is a subsidiary of OVH Groupe SA, a company registered with the Lille Trade and Companies Register under number 537 407 926 and headquartered at 2 rue Kellermann 59100 Roubaix - France",
					publication_director: "Director of publication: Michel Paulin",
				},
			},
		},
		privacy_policy: {
			page_title: "Privacy Policy",
			title: "Privacy Policy",
			subtitle: "Effective Date: April 19, 2024",
			foreword: {
				title: "Foreword",
				content:
					"This privacy policy formalizes our commitment to the protection of users' privacy on the ClapAction website operated by Watch'US. This policy constitutes the contractual framework between the user and Watch'US.",
			},
			data_protection: {
				title: "Compliance with Data Protection Standards",
				content:
					"We process your personal data in strict compliance with the General Data Protection Regulation (GDPR) of April 27, 2016. We commit to collecting only data that is adequate, relevant, and limited to what is necessary for the services offered, without ever including sensitive data.",
			},
			collect: {
				title: "Collection and Use of Personal Data",
				content:
					"Your data is collected during navigation on the site, account creation, financial transactions, or when you contact us. This data is used exclusively for the management and improvement of our services, as well as for communication related to these services.",
			},
			data_security: {
				title: "Data Retention and Security",
				content:
					"We apply advanced technical and organizational measures to ensure the security and integrity of your personal data. However, we remind you that absolute security is not guaranteed on the Internet. It is your responsibility to also secure your login credentials and passwords.",
			},
			personal_data: {
				title: "Sharing of Personal Data",
				content:
					"We only share your data with third parties for management purposes or to meet legal obligations. No transfer outside the European Union is made without appropriate safeguards such as the European Commission's standard contractual clauses.",
			},
			your_rights: {
				title: "Your Rights",
				content:
					"You have extensive rights over your personal data, including access, correction, deletion, or restriction of its processing. You can exercise these rights by contacting us directly through the means provided in this policy.",
			},
			chart: {
				title: "Policy Changes",
				content:
					"This policy may be updated; we invite you to regularly consult this page to stay informed of changes that may affect the processing of your personal data.",
			},
			contact: {
				title: "Contact and Data Protection Officer",
				content:
					"For any questions or to exercise your rights, you can contact us via the email or postal address provided. If applicable, the contact details of our Data Protection Officer are also provided.",
			},
			appeal: {
				title: "Appeal",
				content: "In case of dispute, you have the right to contact the National Commission for Information Technology and Civil Liberties (CNIL).",
			},
		},
		profile: {
			page_title: "Profile",
			change_avatar: "Change profile picture",
			cancel_button: "Cancel",
			submit_button: "Apply modifications",
			update_success: "Your change has been successfully saved.",
			inputs: {
				firstname: {
					label: "First name",
					placeholder: "Jhon",
				},
				lastname: {
					label: "Last name",
					placeholder: "Doe",
				},
				email: {
					label: "Email",
					placeholder: "jhondoe@gmail.com",
				},
				phone: {
					label: "Phone",
					placeholder: "06 18 24 32 17",
				},
				birthdate: {
					label: "Birthdate",
					placeholder: "24/05/1996",
				},
				city: {
					label: "City",
					placeholder: "Paris",
				},
				address: {
					label: "Address",
					placeholder: "18 rue Maréchal-Juin",
				},
				zipCode: {
					label: "Postal code",
					placeholder: "75 001",
				},
				sex: {
					label: "Sex",
					options: [
						{
							label: "Male",
							value: "homme",
						},
						{
							label: "Female",
							value: "femme",
						},
						{
							label: "Other",
							value: "autre",
						},
					],
				},
				password: {
					label: "Password",
					placeholder: "johndoemotdepasse@78",
					additional_info: "At least 8 characters, including 1 uppercase, 1 lowercase, and 1 number.",
				},
			},
		},
		home: {
			page_title: "Home",
			banner: {
				title: "Your ideas come to life on the",
				inside_title: "big screen!",
				subtitle: "Share your ideas and vote for your favorite projects.",
				discover_project: "Discover the projects",
				submit_project: "Submit a project",
			},
			why_join: {
				title: "Why join",
				inside_title: "clapAction ?",
				content:
					"Welcome to clapAction, where your ideas come to life through the power of AI and the unmatched security of blockchain. Whether you are a passionate creator or an ardent supporter of innovative projects, clapAction offers you a unique and secure experience.",
				button: "Create an account",
				creator_title: "Creators",
				creator_content: "Protect your ideas with blockchain technology and, if you wish, share them with film industry professionals.",
				public_title: "Public",
				public_content: "Express your support by voting for the projects that inspire you.",
			},
			marketplace: {
				title: "Coming soon: buy from a collection of authentic",
				inside_title: "certified cinematographic objects",
				subtitle: "Stay connected to be among the first to explore our shop.",
				explore_title: "Explore our shop",
				explore_content: "Dive into our marketplace dedicated to cinema enthusiasts.",
				certified_title: "Certified items",
				certified_content: "A wide selection of authentic and certified items on the blockchain.",
			},
			featured_projects: {
				title: "Projects in",
				inside_title: "development",
				subtitle: "Explore the captivating stories and unique worlds of projects supported by clapAction.",
				discover_project: "Discover the projects",
			},
			storytelling: {
				first_block: {
					title: "Enthusiasts: Join the",
					inside_title: "creation",
					title_2: "and the",
					inside_title_2: "production of films",
					subtitle:
						"Vote to contribute step by step to the production of films: from development (writing, casting...) to release, including shooting and post-production. Interact with the community and enjoy exclusive benefits: access to premieres, sets, and your favorite artists.",
				},
				second_block: {
					title: "Share your ideas",
					inside_title: "securely",
					subtitle:
						"Your documents are anchored on the blockchain, ensuring the complete preservation and security of your creation. Whether you are professionals or amateurs, share your ideas with the assurance that they will be carefully protected for life.",
					additionnal_subtitle: "Note: you can choose to protect your ideas and keep them confidential or submit them to our selection committee.",
				},
				third_block: {
					title: "Professionals: Consult",
					inside_title: "public opinion",
					title_2: "at any time",
					subtitle: "Create a community around your projects, access an opinion barometer, and submit your productions to public votes to guide you in your development.",
				},
			},
			achivement: {
				title: "Our",
				inside_title: "flagship projects",
				subtitle: "The One Who Hadn't Seen Friends",
				content:
					"Greg meets his future wife Rachel a few minutes before the ceremony. She is so beautiful, he wants to say 'Oh my God!' in reference to a character in the series Friends. Rachel doesn't understand his joke, she has never seen the series. Greg is shocked, he doesn't want to get married anymore.",
			},
			FAQ: {
				title: "Frequently Asked Questions",
				questions: [
					{
						title: "How can I submit a movie idea, synopsis, or script ?",
						content: "Create an account, fill out the submission form, and upload your PDF if you have one.",
					},
					{
						title: "What are the criteria for selecting projects ?",
						content: "Projects are selected based on their originality, feasibility, and exploitation potential.",
					},
					{
						title: "How is my project reviewed when submitted, especially if you are seeking development assistance ?",
						content: "Your project is first reviewed by the internal Reading Committee of LaDCF. If selected, it is then presented to the Selection Committee (comprising 10 recognized figures) who decide which films will receive development funding and professional guidance. Discover the LaDCF Selection Committee.",
					},
					{
						title: "How are the Reading Committee results displayed ?",
						content: "You can track the status of your project on the “my projects” page, using three icons: “selected,” “not selected,” or “under review.”",
					},
					{
						title: " What is the response time of the Reading Committee ?",
						content: "There is no specified timeframe by the Reading Committee. On average, it takes one month for a project to be reviewed, but this can vary depending on the number of submissions received each week. For reference, the Selection Committee meets quarterly.",
					},
					{
						title: "How can I vote for a project ?",
						content: "Log in to your account, navigate to the project page, and vote using the available options.",
					},
					{
						title: "Is there a limit on the number of project submissions ?",
						content: "There is no limit to the number of projects you can submit on the platform. You can submit as many projects as you want, and they will benefit from blockchain authentication. All project submissions have legal value.",
					},
					{
						title: "Can a submitted project be modified or deleted ?",
						content: "As long as the project is not anchored on the blockchain, it can be modified. Once anchored, it will be permanently recorded on the blockchain and can no longer be modified or deleted.",
					},
					{
						title: "Can I submit a video with my project ?",
						content: "You cannot directly upload videos to the platform, but you can include a link to the video in your synopsis or CV.",
					},
					{
						title: "Does the platform help individuals find a producer ?",
						content: "If you submit a project as an individual, it will automatically be reviewed by the LaDCF Committee. If you are an author, director, or producer, you are categorized as a professional. By publishing your project on the platform, you can connect with producers, broadcasters, and distributors through the professional space, interact with the public, and build a community around your project starting from the development stage.",
					},
				],
			},
		},
		confirmation_code: {
			page_title: "Code confirmation",
			title: "Authentification code",
			subtitle: "Enter the OTP code we have sent to your registered email address",
			button: "Log in",
			send_code: "Send a new code",
		},
		my_projects: {
			page_title: "Project name",
			title: "My Submitted Projects",
			filters: {
				all_projects: "All projects",
				accepted: "Retained",
				refused: "Not retained",
				studying: "Studying",
				archived: "Archived",
			},
			table: {
				headers: {
					project_name: "Project name",
					ai_image: "IA image",
					anchor_proof: "Anchor proof",
					status: "Status",
					date: "Date",
				},
				waiting: "Waiting...",
				see_image: "See image",
				anchor_link: "Anchor link",
				download: "Download",
				anchor: "Anchor",
				menu: {
					anchor: "Anchor",
					download_proof: "Download proof",
					data: "Data",
					edit: "Edit",
				},
			},
			drawer: {
				button: {
					anchor: "Anchor",
					modify: "Modify",
					modify_socials: "Modify socials",
					download_proof: "Download proof",
				},
				modal: {
					title: "Are you sure you want to anchor this project?",
					content1:
						"Anchoring is a permanent action that locks your project in its current state. Once you have anchored the project, you will no longer be able to modify its contents. This operation is irreversible and guarantees that the data cannot be altered subsequently.",
					content2: "Please ensure all necessary changes have been made before confirming this action.",
					button: {
						confirm: "Anchor",
						cancel: "Cancel",
					},
				},
			},
		},
		submit_project: {
			page_title: "Submit a project",
			title: "Submit a project",
			description: {
				message:
					"Securely share your ideas by filling out the fields below. An Artificial Intelligence will automatically generate a film poster corresponding to your idea, thus accompanying your project. This poster will be visible exclusively to you.",
				items: [
					{ text: "When you submit a project, it remains confidential, and no one can access your information." },
					{
						text: "Regardless of the reason for your submission, it will be anchored on the blockchain, and you will receive a certificate of authenticity linked to your project.",
					},
				],
			},
			labels: {
				project_title: "Project Title",
				project_title_placeholder: "Maximum 100 characters",
				project_format: "Format",
				project_reason: "What is the main reason you are submitting your project?",
				project_pitch: "Pitch",
				project_pitch_additionnal: "Please provide a concise and appealing description. This description will be used to highlight your project on the platform.",
				project_pitch_placeholder: "Pitch (minimum 150 characters)",
				project_linkedin: "Please provide a LinkedIn link if you don't provide your CV",
				cv: "CV",
				scenario: "Synopsis and/or Artistic Elements",
			},
			selections: {
				format: {
					long_metrage: "Feature Film",
					court_metrage: "Short Film",
					serie: "Series",
					documentaire: "Documentary",
				},
				reason: {
					protect: "I am submitting my project simply to protect it",
					protect_and_development_aid: "I am submitting my project to protect it and submit it for development assistance",
				},
				purposes : {
					funding: "Submit it to the LaDCF (La Diversité du Cinéma Français) selection committee in order to qualify for development aid",
					protect: "Exclusively to protect it and obtain a certificate of authenticity",
					pitch: "Get a chance to pitch it to targeted professionals based on my topic",
				}
			},
			drag_and_drop: {
				cv: "Drag or click here to upload your CV",
				scenario: "Drag or click here to upload your document",
			},
			confidentiality: {
				scenarioProtection: {
					text: "Note : Note: By submitting your project on clapAction, the script will be protected on the blockchain. If you want your project to be reviewed by the Selection Committee, please do not exceed 2 typed pages.",
				},
				projectSharing: {
					text: "If you wish to submit your project to share it publicly with the community, please visit the ",
					suscribePageLink: "dedicated space for professionals.",
				},
			},
			send_button: "Submit the project",
		},
		edit_project: {
			page_title: "Edit a project",
			title: "Edit a project",
			subtitle: "Modify the fields below to update your project.",
			labels: {
				project_title: "Project Title",
				project_format: "Format",
				project_reason: "What is the main reason you are submitting your project?",
				project_pitch: "Pitch",
				project_pitch_additionnal: "Please provide a concise and appealing description. This description will be used to highlight your project on the platform.",
				project_pitch_placeholder: "Pitch (minimum 150 characters)",
				project_linkedin: "Please provide a LinkedIn link if you don't provide your CV",
				cv: "CV",
				scenario: "Synopsis and/or Artistic Elements",
				purpose: "I submit a project for",
			},
			selections: {
				format: {
					long_metrage: "Feature Film",
					court_metrage: "Short Film",
					serie: "Series",
					documentaire: "Documentary",
				},
				reason: {
					protect: "I am submitting my project simply to protect it",
					protect_and_development_aid: "I am submitting my project to protect it and submit it for development assistance",
				},
			},
			drag_and_drop: {
				cv: "Drag or click here to upload your CV",
				scenario: "Drag or click here to upload your document",
			},
			confidentiality:
				"Note: By submitting your project on clapAction, the script will be protected on the blockchain. If you want your project to be reviewed by the Selection Committee, please do not exceed 2 typed pages.",
			send_button: "Edit the project",
		},
		contact: {
			page_title: "Contact",
			title: "Contact us",
			subtitle: "Need information? Send us your questions or comments, we will respond quickly!",
			labels: {
				subject: "Subject",
				email: "Email",
				message: "Message",
				firstname: "First name",
				lastname: "Last name",
				phone: "Phone",
			},
			confirmation: "Thank you for your message, we will respond as soon as possible.",
			button: "Send",
		},
		subscribe: {
			page_title: "Subscribe",
			title: "Get to know your audience and",
			inside_title: "discover their expectations!",
			box: "Are you a professional?",
			subtitle: "Get valuable insights on your audience and influence the industry",
			description: {
				title: "Are you developing films?",
				content1:
					"Create a community around your projects with clapAction! Discover the expectations of the public, involve them in your development and production process, until the release of your film. Subscribe now! Starting in October, you will be able to share exclusively the projects that drive you today to make them the successes of tomorrow. Anticipation is the key to curiosity and happiness... clapAction is one of them.",
				content2: "TBD",
			},
			button_box: {
				title: "Subscribe now!",
				content1: "From January 2025, you will be able to share exclusively the projects that drive you today to make them the successes of tomorrow.",
				content2: "Waiting is the key to happiness… clapAction is one of them.",
				button: "Subscribe",
			},
			abilities_title: "Professionals can do :",
			deposit:
				"Submit their film(s) on the platform at any stage of its production (from development to release) in order to create a community and consult it as soon as they wish",
			federate: "Federate and engage a community around each project, starting by taking advantage of the entire clapAction community.",
			contribute:
				"Ask the public for any subject/question (votes, search for ideas, call for testimonials related to their subject, help with casting, choice of title, posters, music, etc.)",
			use_data: "Have access to all data related to their project.",
			deposit_content: "Upload and share the content of their choice related to their project(s): videos, behind the scenes, ITV, reels, etc.",
			offer: "Offer, if the project already has them, direct links to their social networks.",
			drive: "Support the creation of each community, in a unique way adapted to their subject.",
			shape: "Shape their projects according to public expectations",
			types: {
				title: "Types :",
				type1: "Gender (Man/Woman)",
				type2: "Age range",
				type3: "Places of residence",
				type4: "Kind of movies they like",
				type5: "Content created by their community, linked to their project",
				type6: "Percentage of interest in their project compared to all films on clapAction",
			},
			contact: "Contact us",
			radio: {
				title: "You want to",
				options: {
					build: "Share a film, build and interact with its community, and get to know it",
					access: "Share several films and have access to all data and public expectations",
				},
			},
			confirmation: "Thank you for your message, we will respond as soon as possible.",
			labels: {
				firstname: "First name",
				lastname: "Last name",
				address: "Address",
				phone: "Phone",
				email: "Email",
				message: "Do you have a question? Send us a message",
			},
			button: "Send",
		},
		dashboard: {
			page_title: "Dashboard",
		},
		reset_password: {
			page_title: "Resetting the password",
			title: {
				first: "Resetting the",
				second: "Password",
			},
			subtitle: "Please enter a new password",
			labels: {
				password: "Password",
				password_additionnal: "At least 8 characters",
				password_confirm: "Confirm password",
			},
			reset_button: "Change password",
		},
		legal_notice: {
			page_title: "Legal information",
			title: "Legal information",
			subtitle:
				"In accordance with the provisions of Law No. 2004-575 of 21 June 2004 on confidence in the digital economy, users of the clapAction website are informed of the identity of the various parties involved in its creation and monitoring.",
			edition: {
				title: "Edition du site",
				url: "This site, accessible at the URL https://clapaction.app/ (the Site), is published by :",
				company:
					"CLAPACTION, a company with share capital of 1000 euros, registered with the Paris Trade and Companies Register under number 934 285 610 R.C.S. Paris, with its registered office at 28 RUE DE TURIN, 75008 PARIS, represented by Sarah Lelouch, duly authorised.",
				tva: "The publisher's individual VAT number is: FR35934285610.",
			},
			hosting: {
				title: "Hosting",
				hosted: "The Site is hosted by OVH SAS, located at 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (telephone contact or email: 1007).",
			},
			publication: {
				title: "Director of publication",
				content: "The Site's Publication Director is Sarah Lelouch.",
			},
			contact: {
				title: "Contact us",
				mail: "By e-mail: contact@clapaction.com",
				letter: "By post: 15 AVENUE HOCHE, 75008 PARIS",
				legal: "Legal Notices by clapAction",
			},
		},
		forgot_password: {
			page_title: "Forgot your password?",
			title: "Forgot your password?",
			labels: {
				email: "Email",
				email_additionnal: "*We will send you a link by email to reset your password.",
			},
			confirmation: "An email has been sent to you with a password reset code.",
			send_button: "Send link by email",
			redirect_link: "Back to",
		},
		login: {
			page_title: "Sign in",
			title: "Sign in",
			subtitle: "",
			labels: {
				email: "Email",
				password: "Password",
			},
			password_forgotten: "Forgot your password?",
			login_button: "Sign in",
			redirect_text: "Don't have an account?",
			redirect_link: "Sign up",
		},
		projects: {
			page_title: "Projects",
			title: "Discover the films and series selected by",
			inside_title: "our Committee",
		},
		complete_profile: {
			page_title: "Complete your profile",
			title: "Before submitting a project, complete your profile",
			labels: {
				firstname: "First name",
				lastname: "Last name",
				pseudo: "Pseudo",
				email: "Email",
				password: "Password",
				password_confirmation: "Confirm password",
				password_additionnal: "At least 8 characters",
				checkbox: "I accept the terms of use",
				zipcode: "Postal code",
				city: "City",
				address: "Address",
				phoneNumber: "Phone",
			},
			next_button: "Next",
		},
		register: {
			page_title: "Sign up",
			title: "Sign up",
			subtitle:
				"Join clapAction: submit your film or series ideas in complete confidence, and take part in the creation of clapAction projects. All fields below are mandatory.",
			labels: {
				firstname: "First name",
				lastname: "Last name",
				pseudo: "Pseudo",
				email: "Email",
				password: "Password",
				password_confirmation: "Confirm password",
				password_additionnal: "At least 8 characters",
				checkbox: "I accept the terms of use",
				zipcode: "Postal code",
				city: "City",
				address: "Address",
				phoneNumber: "Phone",
			},
			register_button: "Sign up",
			redirect_text: "Already have an account?",
			redirect_link: "Log in",
		},
		post: {
			title: "Forum"
		}
	},
	component: {
		theme_selector: {
			title: "Genre",
			select: "Select the corresponding genre",
			themes: {
				comedie: "Comedy",
				comedie_romantique: "Romantic comedy",
				comedie_dramatique: "Dramatic comedy",
				biopic: "Biopic",
				thriller: "Thriller",
				film_daction: "Action film",
				film_policier: "Detective film",
				film_dhorreur: "Horror film",
				film_historique: "Historical film",
				science_fiction: "Science fiction",
				film_danimation: "Animated Film",
				comedie_musicale: "Musical comedy",
				dramédie: "Drama",
				comedie_absurde: "Absurd comedy",
				thriller_psychologique: "Psychological thriller",
			},
		},
		featured_project: {
			published_at: "Published on: {{var1}}",
			category: "Genre:",
			length: "Format:",
			button: "See the project",
		},
		drag_and_drop_file: {
			no_file_selected: "No file selected",
		},
		authpagetemplate: {
			footer: {
				copyright: "© 2024 clapAction",
			},
		},
		lang_selector: {
			fr: "FR",
			en: "EN",
		},
		header: {
			login: "Sign in",
			register: "Sign up",
			submit_project: "Submit a project",
			my_projects: "My submitted projects",
			personnal_informations: "Personal information",
			home: "Home",
			marketplace: "Marketplace",
			projects: "The projects",
			contact: "Contact",
			subscribe: "Professionals",
			all_projects: "All projects",
			logout: "Log out",
			coming_soon: {
				title: "The site is in BETA!",
				message: "We continue to improve it and new features will be coming soon. Thank you for your patience and feedback!",
			},
		},
	},
	enums: {
		EValidationErrors: EValidationErrorImplementation,
		EProjectThemes: EProjectThemeImplementation,
		EProjectFormats: EProjectFormatImplementation,
		EProjectReasons: EProjectReasonImplementation,
	},
};

export default trads;
