import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { useCallback, useEffect, useState } from "react";
import { container } from "tsyringe";
import { useLocation, useNavigate } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";
import ProjectService from "services/ProjectService";
import ProjectForm from "./ProjectForm";

const projectService = container.resolve(ProjectService);
const modules = container.resolve(ModuleConfig).get().modules;

export default function EditProject() {
	const navigate = useNavigate();
	const location = useLocation();
	const [project, setProject] = useState<ProjectResponseResource | null>(null);

	const getProjectIdInUrl = useCallback(() => {
		const url = location.pathname;
		const urlParts = url.split("/");
		const projectId = urlParts[urlParts.length - 1];
		return projectId;
	}, [location]);

	const fetchProject = useCallback(async () => {
		const projectId = getProjectIdInUrl();
		if (!projectId) {
			console.error("No project id in url");
			navigate(modules.pages.Home.props.path);
			return;
		}
		const project = await projectService.getProjectById(projectId);
		setProject(project);
	}, [getProjectIdInUrl, navigate]);

	useEffect(() => {
		fetchProject();
	}, [fetchProject]);

	if (!project) return null;
	if (project.scenario?.anchor) return <ProjectForm project={project} editSocialsOnly={true} />;
	return <ProjectForm project={project} />;
}
