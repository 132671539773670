"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERuleNames = void 0;
exports.ERuleNames = {
    project__create: "project__create",
    question__create: "question__create",
    access__dashboard: "access__dashboard",
    access__admin_dashboard: "access__admin_dashboard",
    access__my_projects: "access__my_projects",
    access__submit_project: "access__submit_project",
    access__my_profile: "access__my_profile",
    access__project: "access__project",
    can__confirm_account: "can__confirm_account",
    can__modify_project_status: "can__modify_project_status",
    can__archive_project: "can__archive_project",
    post__create: "post__create",
    comment__create: "comment__create",
    comment__delete: "comment__delete",
    post__delete: "post__delete",
};
