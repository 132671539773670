import PageTemplate from "components/PageTemplate";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import Typography from "components/elements/Typography";
import Table from "./Table";
import { container } from "tsyringe";
import ProjectService from "services/ProjectService";
import { useCallback, useEffect, useState } from "react";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import PosterModal from "./PosterModal/PosterModal";
import Pagination from "components/elements/Pagination";
import Drawer from "./Drawer";
import { EPosterStatus } from "common/enums/Project/Poster/EPosterStatus";

const projectService = container.resolve(ProjectService);
const projectsPerPage = 20;

function clamp(value: number, min: number, max: number): number {
	return Math.min(Math.max(value, min), max);
}

function clampPage(page: number, totalPages: number): number {
	return clamp(page, 1, Math.max(1, totalPages));
}

export default function MyProjects() {
	const [projectList, setProjectList] = useState<ProjectResponseResource[]>([]);
	const [selectedProject, setSelectedProject] = useState<ProjectResponseResource | null>(null);
	const [canChangeSelectedProjectPosterImage, setCanChangeSelectedProjectPosterImage] = useState<boolean>(false);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isPosterOpen, setIsPosterOpen] = useState(false);
	const [posterUrl, setPosterUrl] = useState("");
	const [projectCount, setProjectCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);

	const getProjectList = useCallback(async (currentPage: number) => {
		const pagination = { skip: (currentPage - 1) * projectsPerPage, take: projectsPerPage };
		const projectList = await projectService.getMyProjects(pagination);
		setProjectList(projectList);
		return projectList;
	}, []);

	const onSelectProject = useCallback(async (project: ProjectResponseResource) => {
		setSelectedProject(project);
		setCanChangeSelectedProjectPosterImage(false);

		if (project.poster) {
			await projectService.canDiscardGeneratedPosterImage(project.id).then(({ canDiscard }) => {
				setCanChangeSelectedProjectPosterImage(canDiscard);
			});
		}

		return project;
	}, []);

	const updateProjectList = useCallback(async (project: ProjectResponseResource) => {
		setProjectList((prev) => prev.map((p) => (p.id === project.id ? project : p)));
		return project;
	}, []);

	const updateProject = useCallback(
		async (project: ProjectResponseResource) => {
			projectService
				.getProjectById(project.id)
				.then((project) => updateProjectList(project))
				.then((project) => onSelectProject(project));
		},
		[onSelectProject, updateProjectList],
	);

	const open = useCallback(() => {
		setIsPosterOpen(true);
	}, []);

	const close = useCallback(() => {
		setIsPosterOpen(false);
		setIsDrawerOpen(false);
	}, []);

	const onViewImageClick = useCallback(
		(url: string) => {
			open();
			setPosterUrl(url);
		},
		[open],
	);

	const downloadProof = async (id: string, title: string) => {
		const file: Blob = await projectService.downloadAnchorProof(id);
		const url = window.URL.createObjectURL(file);
		const a = document.createElement("a");
		a.style.display = "none";
		a.href = url;
		// the filename you want
		a.download = `${title}_anchor_proof.pdf`;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	};

	const onRowClick = useCallback(
		async (project: ProjectResponseResource) => {
			return onSelectProject(project).then(async () => {
				setIsDrawerOpen(true);
				updateProject(project);
			});
		},
		[onSelectProject, updateProject],
	);

	const onChangeGeneratedPosterImage = useCallback(async () => {
		if (!selectedProject) return;

		setCanChangeSelectedProjectPosterImage(false);

		projectService.changeGeneratedPosterImage(selectedProject.id).then((_change) => {
			updateProject(selectedProject);
		});
	}, [selectedProject, updateProject]);

	const countProjects = useCallback(async () => {
		const total = await projectService.countMyProjects();

		if (total === null) {
			console.error("Total project count is not found");
			return;
		}

		setProjectCount(total);
	}, []);

	const getTotalPages = useCallback(() => Math.max(1, Math.ceil(projectCount / projectsPerPage)), [projectCount]);

	const onChangePage = useCallback(
		async (currentPage: number) => {
			setCurrentPage(clampPage(currentPage, getTotalPages()));
			await getProjectList(currentPage);
		},
		[getProjectList, getTotalPages],
	);

	const onProjectChange = useCallback(async () => {
		await countProjects();
		const updatedProjectList = await getProjectList(currentPage);

		if (updatedProjectList.length === 0 && currentPage > 1) return onChangePage(currentPage - 1);

		const updatedProject = updatedProjectList.find((p) => p.id === selectedProject?.id);
		if (!updatedProject) {
			console.warn("Selected Project not found in the list, maybe because drawer is not open");
			return;
		}
		setSelectedProject(updatedProject);
	}, [countProjects, currentPage, getProjectList, onChangePage, selectedProject?.id]);

	useEffect(() => {
		const initialPage = 1;

		countProjects();
		getProjectList(initialPage);
	}, [countProjects, getProjectList]);

	useEffect(() => {
		if (!isDrawerOpen) return;
		let timeout = 0;
		const updateProjectTimeout = () => {
			timeout = window.setTimeout(() => {
				if (!isDrawerOpen) return;
				// If the project is not anymore in pending or in progress status, do not try to update project
				if (
					selectedProject?.poster?.status !== EPosterStatus.DISCARDED &&
					selectedProject?.poster?.status !== EPosterStatus.PENDING &&
					selectedProject?.poster?.status !== EPosterStatus.IN_PROGRESS
				)
					return;
				if (selectedProject) updateProject(selectedProject);
			}, 5000);
		};

		updateProjectTimeout();
		return () => clearTimeout(timeout);
	}, [selectedProject, selectedProject?.poster?.status, updateProject, isDrawerOpen]);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.my_projects.page_title)} mainSectionClassName={classes["root"]}>
			<Typography type="h1" weight="bold" className={classes["title"]}>
				{I18n.trslt(I18n.asset.pages.my_projects.title)}
			</Typography>
			<Table projectList={projectList} onViewImageClick={onViewImageClick} onRowClick={onRowClick} downloadProof={downloadProof} onProjectChange={onProjectChange} />
			<PosterModal isOpen={isPosterOpen} posterUrl={posterUrl} onClose={close} />
			<Drawer
				isOpen={isDrawerOpen}
				onClose={close}
				onChangeGeneratedPosterImage={onChangeGeneratedPosterImage}
				canChangeGeneratedPosterImage={canChangeSelectedProjectPosterImage}
				project={selectedProject}
				onProjectChange={onProjectChange}
				downloadProof={downloadProof}
			/>
			<div className={classes["pagination"]}>
				<Pagination lastPage={getTotalPages()} currentPage={currentPage} onChangePage={onChangePage} />
			</div>
		</PageTemplate>
	);
}
