import React from "react";
import PostResponseResource from "common/resources/Post/PostResponseResource";

interface ForumHeaderProps {
	post: PostResponseResource;
}

export function ForumHeader({ post }: ForumHeaderProps) {
	return (
		<header className="forum-header">
			<div className="forum-header__content">
				<h1 className="forum-header__title">{post.title}</h1>

				{post.subjects.map((subject) => {
					return (
						<div className="forum-header__subject-container">
							<h3 className="forum-header__subject-title">{subject.title}</h3>
							<div className="forum-header__characteristics">
								{subject.tags.map(({ key, value }) => (
									<div key={key} className="forum-header__characteristics-item">
										<span className="forum-header__characteristics-item-label">{key}:</span>
										<span className="forum-header__characteristics-item-value">{value}</span>
									</div>
								))}
							</div>
							<p className="forum-header__description">{subject.description}</p>
						</div>
					);
				})}
			</div>
		</header>
	);
}
