import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { container } from "tsyringe";
import MenuModal from "components/elements/MenuModal";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import { ArrowDownCircleIcon } from "@heroicons/react/20/solid";
import { EyeIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import ModuleConfig from "configs/ModuleConfig";

type IProps = {
	project: ProjectResponseResource;
	onRowClick: (project: ProjectResponseResource) => void;
	downloadProof: (id: string, title: string) => void;
	openAnchorModal: () => void;
	onProjectChange: () => void;
};

const modules = container.resolve(ModuleConfig).get().modules;

export default function Options(props: IProps) {
	const navigate = useNavigate();

	const navigateToEdit = () => {
		if (props.project === null) return;
		navigate(modules.pages.EditProject.props.path.replace(":id", props.project.id));
	};

	let options: React.ReactNode[] = [];
	if (props.project.scenario?.anchor?.txLink) {
		options.push(
			<div
				onClick={(e) => {
					e.stopPropagation();
					props.downloadProof(props.project.id, props.project.title);
				}}
				className={classes["option-item"]}>
				<ArrowDownTrayIcon className={classes["icons"]} />
				<Typography type="span" size="medium" weight="regular">
					{I18n.trslt(I18n.asset.pages.my_projects.table.menu.download_proof)}
				</Typography>
			</div>,
		);
	} else if (!props.project.scenario?.anchor?.id) {
		options.push(
			<div
				onClick={(e) => {
					e.stopPropagation();
					props.openAnchorModal();
				}}
				className={classes["option-item"]}>
				<ArrowDownCircleIcon className={classes["icons"]} />
				<Typography type="span" size="medium" weight="regular">
					{I18n.trslt(I18n.asset.pages.my_projects.table.menu.anchor)}
				</Typography>
			</div>,
		);
		options.push(
			<div
				onClick={(e) => {
					e.stopPropagation();
					navigateToEdit();
				}}
				className={classes["option-item"]}>
				<PencilIcon className={classes["icons"]} />
				<Typography type="span" size="medium" weight="regular">
					{I18n.trslt(I18n.asset.pages.my_projects.table.menu.edit)}
				</Typography>
			</div>,
		);
	}
	options.push(
		<div
			onClick={(e) => {
				e.stopPropagation();
				props.onRowClick(props.project);
			}}
			className={classes["option-item"]}>
			<EyeIcon className={classes["icons"]} />
			<Typography type="span" size="medium" weight="regular">
				{I18n.trslt(I18n.asset.pages.my_projects.table.menu.data)}
			</Typography>
		</div>,
	);

	return (
		<MenuModal options={options}>
			<EllipsisVerticalIcon width={24} height={24} />
		</MenuModal>
	);
}
