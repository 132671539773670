import CommentApi from "api/clapaction/CommentApi";
import { container, singleton } from "tsyringe";

@singleton()
export default class CommentService {
	public api = container.resolve(CommentApi);

	public async getComments(...args: Parameters<CommentApi["getComments"]>) {
		return this.api.getComments(...args);
	}

	public async createComment(...args: Parameters<CommentApi["createComment"]>) {
		return this.api.createComment(...args);
	}

	public async likeComment(...args: Parameters<CommentApi["likeComment"]>) {
		return this.api.likeComment(...args);
	}

    public async deleteComment(...args: Parameters<CommentApi["deleteComment"]>) {
        return this.api.deleteComment(...args);
    }

	public async getComment(...args: Parameters<CommentApi["getComment"]>) {
		return this.api.getComment(...args);
	}
}
