"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPosterStatus = void 0;
const enum_1 = require("../../../utils/enum");
exports.EPosterStatus = (0, enum_1.strEnum)([
    "PENDING",
    "IN_PROGRESS",
    "COMPLETED",
    "FAILED",
    "DISCARDED",
]);
