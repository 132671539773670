import React from "react";
import classes from "./classes.module.scss";
import classNames from "classnames";

export default class AnimatedLoader extends React.Component<{}> {
	public override render(): JSX.Element {
		return <div className={classNames(classes["root"])}>
			<div className={classNames(classes["dot-pulse"])}></div>
			<div className={classNames(classes["dot-pulse"])}></div>
			<div className={classNames(classes["dot-pulse"])}></div>
        </div>
	}
}
