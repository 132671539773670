import PostCreateRequestResource from "common/resources/Post/PostCreateRequestResource";
import PostResponseResource from "common/resources/Post/PostResponseResource";
import { singleton } from "tsyringe";
import BaseApi from "../BaseApi";

@singleton()
export default class PostApi extends BaseApi {
    private baseurl = `${this.apiUrl}`;

    public async getPosts(projectId: string) {
        const url = `${this.baseurl}/projects/${projectId}/posts`;
        return this.getRequest<PostResponseResource[]>(url);
    }

    public async getPostById(postId: string) {
        const url = `${this.baseurl}/posts/${postId}`;
        return this.getRequest<PostResponseResource>(url);
    }

    public async createPost(postData: PostCreateRequestResource) {
        const url = `${this.baseurl}/posts`;
        return this.postRequest<PostResponseResource>(url, { ...postData });
    }
}