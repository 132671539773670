import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { container } from "tsyringe";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import ProjectService from "services/admin/ProjectService";

type IProps = {
	project: ProjectResponseResource;
	isSmallScreen: boolean;
};

const projectService = container.resolve(ProjectService);

export default function Drawer(props: IProps) {
	const downloadProof = async (id: string, title: string) => {
		const file: Blob = await projectService.downloadAnchorProof(id);
		const url = window.URL.createObjectURL(file);
		const a = document.createElement("a");
		a.style.display = "none";
		a.href = url;
		// the filename you want
		a.download = `${title}_anchor_proof.pdf`;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return (
		<Button
			color={EButtonColor.PRIMARY}
			variant={EButtonVariant.CONTAINED}
			onClick={() => downloadProof(props.project.id, props.project.title)}
			fullwidth={props.isSmallScreen}>
			<Typography type="span" size="medium" weight="regular">
				{I18n.trslt(I18n.asset.pages.my_projects.drawer.button.download_proof)}
			</Typography>
		</Button>
	);
}
