import classes from "./classes.module.scss";
import { ReactComponent as Studying } from "assets/images/Icons/studying.svg";
import { ReactComponent as Accepted } from "assets/images/Icons/accepted.svg";
import { ReactComponent as Refused } from "assets/images/Icons/refused.svg";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import Typography from "components/elements/Typography";
import I18n from "../I18n";
import { match } from "ts-pattern";
import Tooltip from "components/elements/Tooltip";

type IProps = {
	status: EProjectStatus;
	withDescription?: boolean;
};

export default function StatusIcon(props: IProps) {
	const getIcon = () => {
		return match(props.status)
			.with(EProjectStatus.STUDYING, () => <Studying />)
			.with(EProjectStatus.ACCEPTED, () => <Accepted />)
			.with(EProjectStatus.REFUSED, () => <Refused />)
			.exhaustive();
	};

	const getStatusTranslation = () => {
		return match(props.status)
			.with(EProjectStatus.STUDYING, () => I18n.trslt(I18n.asset.pages.my_projects.filters.studying))
			.with(EProjectStatus.ACCEPTED, () => I18n.trslt(I18n.asset.pages.my_projects.filters.accepted))
			.with(EProjectStatus.REFUSED, () => I18n.trslt(I18n.asset.pages.my_projects.filters.refused))
			.exhaustive();
	};

	return (
		<div className={classes["root"]}>
			{!props.withDescription && (
				<Tooltip title={getStatusTranslation()}>
					{getIcon()}
				</Tooltip>
			)}
			{props.withDescription && getIcon()}
			{props.withDescription && <Typography>{getStatusTranslation()}</Typography>}
		</div>
	);
}
