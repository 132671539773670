import PostResponseResource from "common/resources/Post/PostResponseResource";
import { Send, X } from 'lucide-react';
import React, { useRef, useState } from 'react';

interface CommentFormProps {
  post: PostResponseResource;
  onSubmit: (text: string, imageUrl?: string) => void;
}

export function CommentForm({post, onSubmit }: CommentFormProps) {
  const [text, setText] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [showImageUrl, setShowImageUrl] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (text.trim()) {
      onSubmit(text, previewImage || imageUrl);
      setText('');
      setImageUrl('');
      setPreviewImage(null);
      setShowImageUrl(false);
    }
  };

  // const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreviewImage(reader.result as string);
  //       setShowImageUrl(false);
  //       setImageUrl('');
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleRemoveImage = () => {
    setPreviewImage(null);
    setImageUrl('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <form onSubmit={handleSubmit} className="comment-form">
      <h2 className="comment-form__title">
        {post.subtitle}
      </h2>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="comment-form__textarea"
        placeholder="Partagez votre avis..."
        rows={4}
      />
      
      <div className="comment-form__actions">
        {/* <button
          type="button"
          onClick={() => fileInputRef.current?.click()}
          className="comment-form__media-button"
        >
          <Image size={18} />
          Ajouter une photo
        </button>
        <button
          type="button"
          onClick={() => setShowImageUrl(!showImageUrl)}
          className="comment-form__media-button"
        >
          <Link size={18} />
          URL d'image
        </button> */}

        <button type="submit" className="comment-form__submit">
          <Send size={18} />
          Publier
        </button>
      </div>
{/* 
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        className="hidden"
      /> */}

      {showImageUrl && (
        <input
          type="url"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          placeholder="Entrez l'URL de l'image"
          className="input mt-md"
        />
      )}

      {(previewImage || imageUrl) && (
        <div className="comment-form__preview">
          <img
            src={previewImage || imageUrl}
            alt="Preview"
          />
          <button
            type="button"
            onClick={handleRemoveImage}
            className="comment-form__preview-remove"
          >
            <X size={16} />
          </button>
        </div>
      )}
    </form>
  );
}