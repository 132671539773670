import classes from "./classes.module.scss";
import Typography from "components/elements/Typography";
import I18n from "../../I18n";
import { XMarkIcon } from "@heroicons/react/20/solid";

type IProps = {
	onClose: () => void;
};

export default function ComingSoon(props: IProps) {
	return (
		<div className={classes["root"]}>
			<div className={classes["content"]}>
				<div className={classes["title"]}>
					<Typography type="span" size="medium" weight="bold" color="white">
						{I18n.trslt(I18n.asset.component.header.coming_soon.title)}
					</Typography>
				</div>
				<div className={classes["message"]}>
					<Typography type="span" size="medium" weight="medium" color="white">
						{I18n.trslt(I18n.asset.component.header.coming_soon.message)}
					</Typography>
				</div>
			</div>
			<div className={classes["close-container"]}>
				<XMarkIcon className={classes["close-icon"]} onClick={props.onClose} color="white" />
			</div>
		</div>
	);
}
