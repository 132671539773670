import { EGender } from "common/enums/Gender/EGenter";
import { ValidationError } from "common/resources/Resource";
import UserRegisterRequestResource from "common/resources/User/UserRegisterRequestResource";
import AuthPageTemplate from "components/PageTemplate/AuthPageTemplate";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Checkbox from "components/elements/Checkbox";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import Input from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import ModuleConfig from "configs/ModuleConfig";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "services/AuthService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import useNavigateKeepSearchParams from "hooks/useNavigateKeepSearchParams";

const modules = container.resolve(ModuleConfig).get().modules;
const authService = container.resolve(AuthService);

export default function Register() {
	const navigate = useNavigateKeepSearchParams();
	const [hasAcceptedConditions, setHasAcceptedConditions] = useState<boolean>(false);
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const redirectToLoginPage = useCallback(() => {
		navigate(modules.pages.Login.props.path);
	}, [navigate]);

	const onCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setHasAcceptedConditions(e.currentTarget.checked), []);

	const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
		event.preventDefault();
		setErrors([]);
		const userRequest = UserRegisterRequestResource.hydrate<UserRegisterRequestResource>({
			email: formData["email"] as string,
			firstName: formData["firstName"] as string,
			lastName: formData["lastName"] as string,
			password: formData["password"] as string,
			confirmPassword: formData["confirmPassword"] as string,
			phoneNumber: formData["phoneNumber"] as string,
			address: formData["address"] as string,
			zipCode: formData["zipCode"] as string,
			city: formData["city"] as string,
			gender: formData["gender"] as EGender,
			birthDate: formData["birthdate"] ? new Date(formData["birthdate"] as string) : undefined,
			lang: I18n.getLang(),
		});

		userRequest
			.validateOrReject()
			.then(() => {
				authService
					.register(userRequest)
					.then(() => {
						navigate(modules.pages.ConfirmationCode.props.path);
					})
					.catch((error) => {
						console.error({ error });
						if (error instanceof Array) {
							setErrors(error);
						}
					});
			})
			.catch((error) => {
				console.error({ error });
				if (error instanceof Array) {
					setErrors(error);
				}
			});
	}, [navigate]);

	return (
		<AuthPageTemplate tabTitle={I18n.trslt(I18n.asset.pages.register.page_title)}>
			<div className={classes["root"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.register.title)}
					</Typography>
					<Typography type="p" weight="regular" size="large">
						{I18n.trslt(I18n.asset.pages.register.subtitle)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<div className={classes["input-container"]}>
						<Input name="firstName" label={I18n.trslt(I18n.asset.pages.register.labels.firstname)} required className={classes["input"]} />
						<Input name="lastName" label={I18n.trslt(I18n.asset.pages.register.labels.lastname)} required className={classes["input"]} />
					</div>
					<Input name="email" label={I18n.trslt(I18n.asset.pages.register.labels.email)} required className={classes["input"]} />
					{/* <div className={classNames(classes["input-container"], classes["select-container"])}>
						<Select
							name="gender"
							required
							label={I18n.trslt(I18n.asset.pages.profile.inputs.sex.label)}
							options={I18n.asset.pages.profile.inputs.sex.options}
							className={classes["input"]}
						/>
						<Input
							name="birthdate"
							required
							type="date"
							label={I18n.trslt(I18n.asset.pages.profile.inputs.birthdate.label)}
							placeholder={I18n.trslt(I18n.asset.pages.profile.inputs.birthdate.placeholder)}
							className={classes["input"]}
							max={new Date().toISOString().split("T")[0]}
						/>
					</div>
					<div className={classes["input-container"]}>
						<Input name="phoneNumber" label={I18n.trslt(I18n.asset.pages.register.labels.phoneNumber)} required className={classes["input"]} />
						<Input name="city" label={I18n.trslt(I18n.asset.pages.register.labels.city)} required className={classes["input"]} />
					</div>
					<div className={classes["input-container"]}>
						<Input name="address" label={I18n.trslt(I18n.asset.pages.register.labels.address)} required className={classes["input"]} />
						<Input name="zipCode" label={I18n.trslt(I18n.asset.pages.register.labels.zipcode)} required className={classes["input"]} />
					</div> */}
					<Input name="password" label={I18n.trslt(I18n.asset.pages.register.labels.password)} required type="password" className={classes["input"]} />
					<Input
						name="confirmPassword"
						label={I18n.trslt(I18n.asset.pages.register.labels.password_confirmation)}
						required
						type="password"
						additionalInfo={I18n.trslt(I18n.asset.pages.register.labels.password_additionnal)}
						className={classes["input"]}
					/>

					<div className={classes["submit-container"]}>
						<div className={classes["buttons-container"]}>
							<Checkbox checked={hasAcceptedConditions} onChange={onCheckboxChange} uid="conditions" label={I18n.trslt(I18n.asset.pages.register.labels.checkbox)} />
							<Button
								variant={EButtonVariant.CONTAINED}
								color={EButtonColor.PRIMARY}
								size={EButtonSize.LARGE}
								fullwidth
								disabled={!hasAcceptedConditions}
								type="submit">
								{I18n.trslt(I18n.asset.pages.register.register_button)}
							</Button>
						</div>

						<div className={classes["login-redirect-container"]}>
							<Typography type="p" weight="regular" size="medium">
								{I18n.trslt(I18n.asset.pages.register.redirect_text)}
							</Typography>
							<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM} onClick={redirectToLoginPage}>
								{I18n.trslt(I18n.asset.pages.register.redirect_link)}
							</Button>
						</div>
					</div>
				</Form>
			</div>
		</AuthPageTemplate>
	);
}
