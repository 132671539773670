"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAnchorStatus = void 0;
const enum_1 = require("../../utils/enum");
exports.EAnchorStatus = (0, enum_1.strEnum)([
    "QUEUED",
    "ATTEMPTING",
    "VERIFIED_ON_CHAIN",
    "VERIFYING_ON_CHAIN",
    "ABANDONED",
]);
