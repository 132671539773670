import CommentResponseResource from "common/resources/Comment/CommentResponseResource";
import CommentCreateRequestResource from "common/resources/Comment/CommentCreateRequestResource";
import { singleton } from "tsyringe";
import BaseApi from "../BaseApi";

@singleton()
export default class CommentApi extends BaseApi {
	private baseurl = `${this.apiUrl}`;

	public async getComments(postId: string) {
		const url = `${this.baseurl}/posts/${postId}/comments`;
		return this.getRequest<CommentResponseResource[]>(url);
	}

	public async createComment(postId: string, commentData: CommentCreateRequestResource) {
		const url = `${this.baseurl}/posts/${postId}/comments`;
		return this.postRequest<CommentResponseResource>(url, { ...commentData });
	}

	public async likeComment(commentId: string) {
		const url = `${this.baseurl}/comments/${commentId}/like`;
		return this.postRequest<{ liked: boolean }>(url);
	}

	public async deleteComment(commentId: string) {
		const url = `${this.baseurl}/comments/${commentId}`;
		return this.deleteRequest<void>(url);
	}

	public async getComment(commentId: string) {
		const url = `${this.baseurl}/comments/${commentId}`;
		return this.getRequest<CommentResponseResource>(url);
	}
}
