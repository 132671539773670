import React from 'react';
import { MessageCircle } from 'lucide-react';
import classes from "./classes.module.scss"

export function EmptyComments() {
  return (
    <div className={classes["root"]}>
      <MessageCircle className="mx-auto h-12 w-12 text-gray-400 mb-4" />
      <h3 className="text-lg font-medium text-gray-900 mb-2">Aucun commentaire</h3>
      <p className="text-gray-500">Soyez le premier à partager votre avis !</p>
    </div>
  );
}