import ProjectApi from "api/clapaction/ProjectApi";
import ProjectCreateRequestResource from "common/resources/Project/ProjectCreateRequestResource";
import ProjectReponseResource from "common/resources/Project/ProjectResponseResource";
import ProjectUpdateRequestResource from "common/resources/Project/ProjectUpdateRequestResource";
import ProjectUpdateSocialsRequestResource from "common/resources/Project/ProjectUpdateSocialsRequestResource";
import QuestionResponseResource from "common/resources/Question/QuestionResponseResource";
import ProjectServiceClassToken from "common/resources/injectables/ProjectServiceClassToken";
import { container, singleton } from "tsyringe";
import { Pagination } from "utils/Pagination";
import toValidationError from "utils/toValidationError";

@singleton()
export default class ProjectService implements ProjectServiceClassToken {
	private projectApi: ProjectApi = container.resolve(ProjectApi);

	public async exists(_id: string): Promise<boolean> {
		return this.getProjectById(_id)
			.then(() => true)
			.catch(() => false);
	}

	public async getProjects(): Promise<ProjectReponseResource[]> {
		return this.projectApi.getProjects();
	}

	public async getProjectQuestions(id: string): Promise<QuestionResponseResource[]> {
		return this.projectApi.getProjectQuestions(id);
	}

	public async getProjectById(id: string): Promise<ProjectReponseResource> {
		return this.projectApi.getProjectById(id);
	}

	public async createProject(project: ProjectCreateRequestResource, files: { scenario?: File; cv?: File }): Promise<ProjectReponseResource> {
		return this.projectApi.createProject(project, files).catch(toValidationError);
	}

	public async updateProject(id: string, project: ProjectUpdateRequestResource, files: { scenario?: File; cv?: File }): Promise<ProjectReponseResource> {
		return this.projectApi.updateProject(id, project, files).catch(toValidationError);
	}

	public async updateProjectSocials(id: string, project: ProjectUpdateSocialsRequestResource): Promise<ProjectReponseResource> {
		return this.projectApi.updateProjectSocials(id, project).catch(toValidationError);
	}

	public async createAnchor(projectId: string): Promise<void> {
		return this.projectApi.createAnchor(projectId).catch(toValidationError);
	}

	public async getMyProjects(pagination?: Pagination): Promise<ProjectReponseResource[]> {
		return this.projectApi.getMyProjects(pagination);
	}

	public async countMyProjects(): Promise<number> {
		return this.projectApi
			.countMyProjects()
			.then((res) => res.total)
			.catch(toValidationError);
	}

	public async changeGeneratedPosterImage(projectId: string): Promise<{ discarded: boolean } | { next: true }> {
		return this.projectApi.changeGeneratedPosterImage(projectId);
	}

	public async canDiscardGeneratedPosterImage(projectId: string): Promise<{ canDiscard: boolean }> {
		return this.projectApi.canDiscardGeneratedPosterImage(projectId);
	}

	public async downloadAnchorProof(projectId: string) {
		return this.projectApi.downloadAnchorProof(projectId);
	}

	public async isLinkedInUrlValid(url: string): Promise<boolean> {
		const regex = /^(https?:\/\/)?([a-zA-Z]{2,3}\.)?linkedin\.com\/in\/[a-zA-Z0-9-.]{1,100}\/?$/;

		return regex.test(url);
	}
}
